.admin-popup-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-popup-container {
    width: 30%;
    height: 500px;
	margin: auto;
	background: #18181b;
    border-radius: 5px;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
}

.admin-popup-close {
    display: flex;
    align-items: center;
    justify-content: right;
}


.admin-popup-button-close {
    background-color: #18181b;
    border: none;
    cursor: pointer;
}


.admin-popup-confirm-button {
    border: none;
    padding: 5px;
    width: 125px;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    color: white;
    background-color: #f36196;
    margin-top: 50px;
    cursor: pointer;
}