:root {
    --navbar-height: 50px;
    --channel-info-height: 30px;
}


.vodcard-body {
    position: relative;
    margin: 2px;
    margin-right: 20px;
    width: calc(40dvh - var(--navbar-height) - var(--channel-info-height));
}

.vodcard-image {
    width: 100%;
}

.img-video {
    width: 260px;
    border-radius: 10px;
}

.vodcard-online {
    font-size: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    background-color: #e51a4c;
    color: #ededed;
    text-align: center;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-weight: 600;
    padding: 2px;
    z-index: 10;

}

.vodcard-spectator {
    font-size: 14px;
    position: relative;
    top: 165px;
    left: 10px;
    width: 130px;
    background-color: rgba(0, 0, 0, 0.74);
    color: #ededed;
    text-align: left;
    border-radius: 3px;
    z-index: 10;
}

.vodcard-body h3 {
    color: rgb(255, 255, 255);
    margin-top: 3px;
}

.vodcard-body h3:hover {
    color: #f36196;
}


.vodcard-body {
    transition: 0.5s all;
    height: 210px;
}


.vodcard-container:hover .vodcard-image {
    font-weight: bold;
    border-radius: 5px;
    box-shadow: #f36196 -6px 6px;
    transform: translate3d(6px, -6px, 0);
    cursor: pointer;
}

.vodcard-body .vodcard-spectator {
    transition: 0.5s all;
}

.vodcard-container:hover .vodcard-spectator {
    transform: translate3d(6px, -6px, 0);
    transition: 0.1s all;
}


.vod-tag {
    background-color: #252525;
    padding: 2px;
    border-radius: 50px;
    text-align: center;
    font-size: 10px;
    font-weight: 800;
    cursor: pointer;
    text-decoration: none;
    color: #ededed;
    min-width: 40px;
    max-width: 60px;
    margin-top: 5px;
    margin-right: 5px;
}