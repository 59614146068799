.usersettings-popup-body {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000000000000000000000;
}

.usersettings-popup-container {
    width: 20%;
    height: 425px;
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    padding: 30px;
    text-align: center;
    background: #18181b;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
    z-index: 100000000000000000;
}


.usersettingspopup-card {
    width: 50%;
    background-color: transparent;
    border-radius: 5px;
    padding: 15px;
    border: 1px solid rgb(39, 39, 39);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer !important;
    z-index: 1000;
    margin: 0 auto;
}




.usersettings-popup-close {
    width: 100%;
    display: flex;
    justify-content: right;
}

.usersettings-popup-close button {
    background-color: transparent;
    border: none;
    color: #ededed;
    cursor: pointer;
}


.usersettings-loadfile {
    width: 100%;
    height: 30%;
    top: 114px;
    left: 0;
    position: absolute;
    opacity: 0;
}


.usersettings-crop-container {
    position: relative;
    width: 100%;
    height: 300px;
    background-color: #333;

}


.usersettings-crop-input-container {
    width: 100%;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usersettings-crop-input-container input {
    width: 70%;
}

.usersettings-crop-input-container input[type="range"] {
    -webkit-appearance: none;
    width: 200px;
    height: 2px;
    background: rgb(255, 0, 255);
    background-position: center;
    background-repeat: no-repeat;
}

.usersettings-crop-input-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #434343;
    position: relative;
    border: 3px solid rgb(251, 127, 255);
    z-index: 3;
    cursor: pointer;
}


.usersettings-popup-cancel {
    width: 75px;
    padding: 7px;
    background-color: #333;
    color: #ededed;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.usersettings-popup-save {
    width: 75px;
    padding: 7px;
    background-color: #ff60b2;
    color: #ededed;
    border: none;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
}

.popupgallery-input {
    background-color: rgb(65, 65, 65);
    border: none;
    padding: 5px;
    border-radius: 3px;
    margin-top: 5px;
    width: 250px;
}