/* PerformanceDashboard.css */

.performance-dashboard {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    padding: 20px 0px;
  }
  
  .performance-card {
    background-color: #1f1f1f;
    width: 25%;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    border-top: 4px solid;
    color: white;
  }
  
  .performance-header {
    font-size: 0.8em;
    margin-bottom: 10px;
  }
  
  .stat-number {
    font-size: 2em;
    margin: 10px 0;
  }
  
  .stat-title {
    margin: 0;
    font-size: 0.9em;
  }
  
  .chart-container {
    width: 100%;
    height: 80px;
  }
  