.dashboard-content-body {
    width: 100%;
    min-height: 1000px;
    background-color:#0a0b0d;

}

.dashboard-content-container {
    width: 95%;
    margin: 0 auto;
    position: relative;
    top: 50px;
    color: #ededed;
}

.dashboard-content-type {
    width: 100%;
    min-height: 200px;
}

.dashboard-content-card-primary {
    background-color: #161616;
    width: 100%;
    height: 40px;
    display: flex;
    border: 1px solid #2b2b2b;

}


.dashboard-content-card {
    background-color: #161616;
    width: 100%;
    height: 100px;
    display: flex;
    border-bottom: 1px solid #2b2b2b;
    border-left: 1px solid #2b2b2b;
    border-right: 1px solid #2b2b2b;

}

.dashboard-content-card-checkbox {
    width: 3%;
    height: 100px;
    display: flex;
    align-items: center;
}

.dashboard-content-card-video {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
}

.dashboard-content-card-visibility {
    width: 10%;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
}