
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdownbalance-menu {
    position: absolute;
    top: 52px;
    right: 70px;
    display: flex;
    background: #0a0a0afc;
    box-shadow: 0px 5px 20px 5px black;
    border-radius: 5px;
    font-family: 'Roboto';
    width: 190px;
}
    
.dropdownbalance-menu li {
      display: flex;
      margin: 5px;
      width: 180px;
      font-size: 12px;
}
    
    
.dropdownbalance-menu.clicked {
    display: none;
}
    
.dropdownbalance-button {
      background-color: #f36196;
      border: none;
      width: 100%;
      font-family: 'Poppins';
      font-weight: 600;
      padding: 5px;
      color: black;
      cursor: pointer;
      border-radius: 3px;

}

.dropdownbalance-news-card {
    width: 100%;
    min-height: 200px;
}
    
@media screen and (max-width: 960px) {
  
      .dropdownbalance-menu {
        width: 220%;
        top: 60px;
        z-index: 200;
  
      }
  
      .fa-caret-down {
        display: none;
      }
}