.summary-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: "inter";
    padding-top: 4rem;
}

.summary {
    margin: 10px 0px;
}

.summary-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.navigation-arrow {
    cursor: pointer;
}

.summary-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.summary-details-item {
    display: flex;
    flex-grow: 1;
    height: 100px;
    min-width: 250px;
    margin: 5px 0;
    background: #18181b;
    /* margin: 10px; */
    flex-grow: 1;
    padding: 18px;
    flex-direction: column;
    justify-content: space-between;
}

.property-name {
    display: flex;
    font-weight: bold;
    flex-direction: column;

}

.comparison {
    color: #ff69b433;
    margin-right: 10px;
    /* Color rosado */
    font-family: inter;
}

.comparisonLast {
    color: #ff69b433;
    font-family: inter;
}

.property-value {
    color: #ff69b4;
    font-weight: 700;
    font-family: inter;
}

.SummaryAnalitycsbackgroundDashboarLayout {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
    background-image: url("https://www.pinkker.tv/uploads/assets/Banner2.jpg");
    /* Imagen de fondo */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 20px;
    height: 30%;
    box-shadow: rgb(0, 0, 0) 0px 0px 8px 2px;
    /* Rota la imagen 90 grados */

}