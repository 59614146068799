.cartera-body {
    width: 100%;
    min-height: 1000px;
    background-color: #0A0B0D;
    padding: 0rem 0rem;
}

.cartera-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    margin: 0 auto;
}

.cartera-balance {
    width: 100%;
    height: 65px;
    background-color: #151515;
    border-radius: 5px;
    padding: 25px;
}

.cartera-balance-buttons {
    margin-top: 20px;
}

.cartera-balance-button {
    width: 125px;
    height: 35px;
    background-color: #ff60b2;
    border: none;
    border-radius: 5px;
    color: #ededed;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.cartera-balance-button:hover {
    background-color: #bd3b7e;
}

.cartera-history {
    width: 100%;
    padding: 25px;
    height: 550px;
    background-color: #151515;
    margin-top: 20px;
    border-radius: 5px;
    overflow: scroll;
    overflow-x: hidden;
}

.cartera-history-card {
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #ededed;
    display: flex;
    align-items: center;
    background-color: #303030;
    border-radius: 3px;
}

.cartera-history-card-name {
    width: 20%;
}

.cartera-history-card-destino {
    width: 30%;
}

.cartera-history-card-amount {
    width: 10%;
}

.cartera-history-card-status {
    width: 10%;
}


.cartera-history-card-date {
    width: 20%;
}

.withdraw-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.withdraw-popup .form-group {
    margin-bottom: 15px;
}

.withdraw-popup label {
    display: block;
    margin-bottom: 5px;
}

.withdraw-popup input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.withdraw-popup button {
    margin-right: 10px;
    padding: 10px 15px;
}

/*  retiros */
.cartera-history-card-container {
    width: 100%;
    margin: 20px 0;
}

.cartera-history-card {
    display: flex;
    align-items: center;
    background-color: #2d2d2d;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
}

.cartera-history-card-name,
.cartera-history-card-destino,
.cartera-history-card-amount,
.cartera-history-card-status,
.cartera-history-card-date {
    width: 20%;
}

.cartera-history-card-name h4,
.cartera-history-card-destino h4,
.cartera-history-card-amount h4,
.cartera-history-card-date h4 {
    color: #ededed;
    margin: 0;
}

.status {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
}

.status.accepted {
    background-color: green;
}

.status.rejected {
    background-color: red;
}

.status.pending {
    background-color: orange;
}

/* Tooltip container */
.tooltip-container {
    position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Position above the text */
    left: 50%;
    margin-left: -100px;
    /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}