.navbar-left-mobile {
    width: 100%;
    height: 100px;
    background-color: #0a0a0a;
    position: fixed;
    z-index: 999999;
    bottom: 0;
    color: white;
    border-top: 2px solid #5f5f5f8f;
}


.navbar-left-mobile-container {
    display: flex;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
}

.navbar-left-mobile-card {
    width: 16%;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}
