/* General advertisement container styling */
.advertisement {
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
    max-width: 1200px;
    /* Max width to center and constrain the container */
    padding: 20px;
}

/* Form container styling */
.update-categorie-container {
    padding: 20px;
    background-color: #1e1e1e;
    /* Slightly lighter dark background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

/* Form styling */
.advertisementIter-form {
    display: flex;
    flex-direction: column;
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.advertisementIter-form input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #444;
    color: #fff;
    border-radius: 4px;
}

.advertisementIter-form input::placeholder {
    color: #bbb;
}

.advertisementIter-form button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    background-color: #1a73e8;
    transition: background-color 0.3s ease;
}

.advertisementIter-form button:hover {
    background-color: #155bb5;
}

/* Individual advertisement styling */
.advertisementIter {
    border: 1px solid #333;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #2c2c2c;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 700px;
}

.advertisementIter:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.advertisementIter h2 {
    margin: 0;
    font-size: 1.5em;
    color: #fff;
}

.advertisementIter p {
    color: #ccc;
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px;
    border-left: 4px solid #1a73e8;
    /* Borde a la izquierda para destacar el texto */
    background-color: #1e1e1e;
    /* Fondo ligeramente distinto */
    border-radius: 4px;
}

.advertisementIter button {
    background-color: #1a73e8;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.advertisementIter button:hover {
    background-color: #155bb5;
}

/* Slider button styling */
.slider-btn {
    padding: 20px 2px;
    width: 70px;
    font-size: 12px;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    background-color: #333333;
    transition: background-color 0.3s ease;
    text-wrap: nowrap;
}

.slider-btn:hover {
    background-color: #155bb5;
}

/* Search container styling */
.search-container-advertisement {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
}

.search-container-advertisement input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #444;
    border-radius: 4px;
    color: #fff;
    background-color: #333;
}

.search-container-advertisement button {
    padding: 20px 2px;
    /* width: 64px; */
    font-size: 12px;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    background-color: #333333;
    transition: background-color 0.3s ease;
    text-wrap: nowrap;
}

.search-container-advertisement button:hover {
    background-color: #155bb5;
}

/* Pending Ads styling */
.pending-ads {
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pending-ads-list {
    display: flex;
    flex-direction: column;
}

.ad-item {
    background-color: #1e1e1e;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ad-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.ad-item h3 {
    margin: 0;
    color: #fff;
}

.ad-item p {
    color: #ccc;
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px;
    border-left: 4px solid #1a73e8;
    /* Borde a la izquierda para destacar el texto */
    background-color: #1e1e1e;
    /* Fondo ligeramente distinto */
    border-radius: 4px;
}

.ad-item button {
    background-color: #1a73e8;
    border: none;
    color: #fff;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

.ad-item button:hover {
    background-color: #155bb5;
}