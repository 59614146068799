.usersettings-body-dark {
    background-color: #080808;
    color: #ededed;
    padding: 2rem 0px;
}

.usersettings-body-light {
    width: 100%;
    min-height: 1500px;
    background-color: #f9f9f9;
    color: rgb(0, 0, 0);
}

.usersettings-body-blue {
    width: 100%;
    min-height: 1500px;
    background-color: #192734;
    color: #ededed;
}

.type-set-config {
    display: flex;
    padding: 5px;
    background-color: #f3619608;
    border-radius: 5px;
    gap: 15px;
    width: max-content;
}

.cards-settings {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    padding: 15px;
    background: #4e2232;
}

.item-config:hover {
    background-color: #4e2232;
    border-radius: 5px;
    cursor: pointer;
}

.item-config-active {
    background-color: #4e2232;
    border-radius: 5px;

}

.usersettings-set-type {
    width: 100%;
    display: flex;
    font-family: 'Montserrat';
    letter-spacing: 2px;
    padding: 0px;
    margin-top: 10px;
    border-top: 1px solid #2b2b2b8f;
}

.usersettings-title-card {
    padding: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    color: darkgray;
}

.usersettings-title-card.active {
    color: #ff60b2;
    border-top: 1px solid #ff60b2;
}


.usersettings-settings {
    width: 50%;
    border: 1px solid rgb(42, 46, 56);
    color: #ededed;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #100b0d;
}

.usersettings-change-avatar {
    width: 100%;
    margin-top: 15px;
}

.usersettings-card {
    display: flex;
    align-items: center;
    background-color: #191b1f;
    border-radius: 5px;
    flex-direction: column;
    margin-top: 10px;
}


.button-settings-popup {
    width: 100%;
    padding: 10px;
    background-color: rgb(37, 37, 37);
    color: #ededed;
    font-family: 'Montserrat';
    font-size: 12px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

@media screen and (max-width: 758px) {
    .usersettings-card {
        width: 100%;
    }
}