.biography-container {
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.biography-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #191b1f;
    border-radius: 5px;
    padding: 15px;


}

.biography-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5px;
}


.biography-input input {
    width: 96%;
    background-color: rgb(56, 56, 56);
    border: none;
    outline: none;
    color: white;
    border-radius: 5px;
}

.biography-input select {
    width: 80%;
    height: 3rem;
    background-color: rgb(56, 56, 56);
    border: none;
    padding: 10px;
    outline: none;
    color: white;
    border-radius: 5px;
    margin: 5px auto;
}


.biography-button {
    margin-top: 0px;
    position: relative;
    top: -10px;
    /* font-size: 32px; */
    padding: 5px;
    background-color: transparent;
    border: 1px solid #f36196;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-right: 5px;
}

.biography-button-2FA {
    margin-top: 0px;
    position: relative;
    /* top: -10px; */
    /* font-size: 32px; */
    padding: 5px;
    background-color: transparent;
    border: 1px solid #f36196;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-right: 5px;
    margin: 0;
}

@media screen and (max-width: 758px) {
    .biography-body {
        width: 100%;
    }
}

.totp-modal {
    position: fixed;
    top: 50%;
    display: flex;
    width: 302px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #121418;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 8px;
    flex-direction: column;
    align-items: flex-start;
}

.totp-modal input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.totp-modal button {
    margin: 10px 0px;
}

.totp-modal p {
    text-align: left;
    padding: 15px 0px;
    font-weight: bolder;

}

.biography-2FA {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #191b1f;
    border-radius: 5px;
    padding: 15px;

}