/* Notificaciones.css */

.notifications-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: scroll;
    overflow-y: auto;
    max-height: 80vh;
}

@media screen and (max-width: 768px) {
    .notifications-container {
        max-width: 100%;
        margin: 0 auto;
        padding: 16px;
        border-radius: 12px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        overflow: scroll;
        overflow-y: auto;
        height: 100%;
    }
}

.notifications-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.notification-link {
    text-decoration: none;
    color: inherit;
}

.notification-item {
    display: flex;
    align-items: center;
    padding: 10px;
    /* border-bottom: 1px solid #e6e6e6; */
    transition: background-color 0.2s ease;
    background-color: rgb(8 8 8);
    box-shadow: none;
    width: 100%;
    height: max-content;
}

.notification-item:hover {
    /* background-color: #f9f9f9; */
}

.notification-avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 12px;
    cursor: pointer
}

.notification-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.notification-user {
    text-align: -webkit-left;
    font-size: 18px;
    font-weight: bolder;
    color: #ffffff;
    cursor: pointer
}

.notification-user strong {
    color: #fbace4;
    font-weight: bolder;
    margin-right: 5px;
}

.toggle-text {
    color: #fbace4;
    cursor: pointer
}

.load-more-button {
    display: flex;
    background-color: #1e1e1e;
    justify-content: center;
    height: 21px;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

.load-more-button i {
    color: #ffffff;
}