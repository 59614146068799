.container-channel {
    width: 100% !important;
    background-color: #080808;
    position: relative;
    transition: 0.15s;
    display:flex;
    justify-content: space-between;
}

.container-channel-expand {
    background-color: #080808;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.15s
}

.container-channel-isMobile {
    background-color: #080808;
}

.conetent-player-clip {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    height: 100%;
}

.conetent-player-clip>video {
    max-height: 100%;
    /* margin: 41px 0px 0px 0px; */
}

.channel-body {
    background-color: #080808;
    scrollbar-width: none;
    z-index: 888;
    overflow-x: hidden;
    width: 95%;
}

.hide-scrollbar::-webkit-scrollbar {
    scrollbar-width: none;
    width: 0;
}


.channel-content-video {
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1000;
    justify-content: flex-start;


}

.channel-video {
    display: flex;
    align-items: center;
    transition: 0.15s;
    flex-direction: column;
    width: 100%;
}

.channel-top-stream {
    width: 100%;
    margin: 0 auto;
    height: 75px;
    background-color: transparent;
    display: flex;
    align-items: flex-start;
}

.channel-top-stream-container {
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
}

.channel-chat {
    height: -webkit-fill-available;
    position: fixed;
    right: 0;
    top: 0;
    background-color: #080808;
    transition: 0.15s;
    overflow-y: unset;
    z-index: 8888;
}

.channel-info {
    width: 100%;
    color: #ededed;
    display: flex;
    align-items: flex-start;
    background-color: #303030;
    position: relative;
    top: -30px;
    left: 20px;
}

.channel-primary {
    width: 50%;
    display: flex;
}

.channel-avatar {
    width: 5%;
    text-align: center;
    min-height: 20px;
    margin-right: 20px;
    margin-left: 10px;
}

.channel-avatar-top {
    position: relative;
    left: 5px;
    text-align: center;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.channel-avatar-top-img-container {
    width: 60px;
    height: 70px;
    padding: 3px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
}

.channel-avatar-top-img-container:hover {
    padding: 3px;
}

.channel-avatar-top img {
    width: 50px;
    border-radius: 100px;
    position: relative;
    top: -8px;
}



.channel-avatar-text {
    color: rgb(237, 237, 237);
    padding: 4px;
    border-radius: 5px;
    position: relative;
    left: 12px;
    top: -17px;
    width: 31px;
    height: 12px;
    transition: 0.5s;
    background-color: #e51a4c;
    border: 2px solid #0A0B0D;
    font-size: 13px;
    font-weight: bolder;
}



.channel-top-desc {
    width: 100%;
    color: lightgray;
    font-size: 14px;
    margin-top: 20px;
    overflow: hidden;
}

.button-read-more {
    border: none;
    background-color: transparent;
    color: #ededed;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

.button-read-more:hover {
    color: darkgray;
}

.channel-title {
    width: 100%;
    margin-left: 10px;

}


.channel-button-title-follow {
    width: 80px;
    padding: 5px;
    background-color: #f36196;
    border: none;
    color: #ededed;
    border-radius: 5px;
    margin-left: 25px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-right: 15px;
}

.channel-button-title-follow:hover {
    background-color: #bd3b7e;
}


.channel-button-title-donate {
    background-color: #262626;
    width: 100px;
    padding: 7px;
    border: none;
    color: #ededed;
    border-radius: 2px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.channel-button-title-donate:hover {
    background-color: rgb(54, 54, 54);
}


.channel-title-tag {
    padding: 2px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.4px;
    font-weight: 800;
    cursor: pointer;
    margin-right: 5px;
    color: #f36196;
    border-bottom: 1px solid transparent;

}



.channel-button-unfollow {
    padding: 7px;
    border: none;
    background-color: #262626;
    border-radius: 5px;
    color: #ededed;
    cursor: pointer;
    margin-right: 5px;
}

.channel-team {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: right;
    height: 290px;
}


.channel-secundary {
    width: 50%;
}

.tranform {
    transform: rotate(360deg);


}

.channel-secundary-card:hover {
    cursor: pointer;
    border-radius: 5px;
}

.p-twitter:hover {
    color: lightblue;
}

.p-youtube:hover {
    color: rgb(230, 173, 173);
}

.p-instagram:hover {
    color: rgb(230, 173, 227);
}

.p-tiktok:hover {
    color: rgb(230, 184, 173);
}

.p-facebook:hover {
    color: rgb(177, 173, 230);
}


.channel-button-unfollow:hover {
    color: rgb(255, 255, 255);
    background-color: #d70026;

}

.channel-button-followers {
    background-color: transparent;
    border: none;
    color: rgb(124, 124, 124);
    margin-right: 20px;
    min-width: 50px;
    border-radius: 5px;
    cursor: pointer;
}

.channel-streamer-info a {
    color: darkgray;
    margin: 10px;
    cursor: pointer;
}

.channel-streamer-info a:hover {
    color: #f36196;

}

.channel-buton-effect:hover {
    transition: 0.5s all;
    transform: scale(1.1);
}


.channel-button-left {
    padding: 5px;
    background-color: rebeccapurple;
    border: none;
    color: #ededed;
    width: 150px;
    border-radius: 5px;
    cursor: pointer;
}

.button-more {
    width: 25px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.channel-set-type {
    width: 100%;
    display: flex;
    padding: 0px;
    margin: 0px auto;
    margin-right: 4.3%;
    border-top: 2px solid #2b2b2b8f;
    justify-content: center;
    margin-top: 20px;
}

.video-publicity {
    margin-top: -25px;
}

.announcement {
    background-color: transparent;
}

.announcement-front {
    background-color: transparent;
    position: absolute;
    width: 80%;
    height: 865px;
    z-index: 9999;
    top: 0;
    left: 20px;
}

.announcement-front-button {
    width: 98%;
    height: 790px;
    display: flex;
    justify-content: right;
    align-items: end;
}

.announcement-button {
    padding: 10px;
    border: none;
    font-family: Inter, Roobert, Helvetica Neue, Helvetica, Arial, sans-serif;
    width: 235px;
    font-size: 17px;
    background-color: #040404cf;
    border: 1px solid white;
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

.announcement-navbar {
    width: 78%;
    color: #ededed;
    position: absolute;
    z-index: 500;
    left: 30px;
    top: 55px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.channel-button-like {
    background-color: transparent;
    padding: 4px;
    color: darkgray;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.channel-button-like-animation {
    background-color: transparent;
    padding: 4px;
    color: red;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.channel-button-like:hover {
    background-color: #151515;
    border-radius: 3px;
}

.channel-button-like-animation:hover {
    background-color: #151515;
    border-radius: 3px;
}

.background-player {
    width: 100%;
    height: 755px;
    background-color: black;
}


.heart {
    margin-left: 10px;
    cursor: pointer;
    height: 15px;
    width: 30px;
    background-image: url('https://abs.twimg.com/a/1446542199/img/t1/web_heart_animation.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: 3900%;
}


.is_animating {
    animation: heart-burst .8s steps(28) 1;
    color: red;
}

.channel-desc-link {
    color: #f36196;
    text-decoration: none;
}

.channel-desc-link:hover {
    text-decoration: underline;
}

.channel-button-icon {
    background-color: #262626;
    border: none;
    padding: 5px;
    width: 40px;
    color: #ededed;
    font-size: 14px;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
}


@keyframes heart-burst {
    from {
        background-position: left;
    }

    to {
        background-position: right;
    }
}



/* V2 */

.channel-custom-player-main-div {
    width: 100%;
}

.Channel-info-streamMain {
    width: 96%;
    margin: 0px 0px 0px 0px;
    z-index: 1000;
}

.channel-v2-info {
    width: 96%;
    margin: 0 auto;
    padding: 2px;
    padding-bottom: 7px;
    /* width: 60%;  */
    /* background-color: #131418;
    position: relative; */
    /* margin: 0 auto; */
    /* padding: 2px; */
    /* padding-bottom: 7px; */
    /* display: flex;
    align-items: center;
    top: -4px;
    transition: 0.35s all;
    justify-content: space-between;
    border-radius: 0px 0px 10px 10px;
    border-top: 1px solid #2a2e38; */
}

.channel-v2-primary {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 3px;
    justify-content: space-between;
}

.channel-v2-categorie {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.channel-info-streamer-stream-data {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 3px 0px 0px 5px;

}

.channel-v2-categorie>h4 {
    margin-left: 10px;
}

.channel-v2-categorie img {
    height: 65px;
    border: 2px solid transparent;
    cursor: pointer;

}

.channel-v2-categorie img:hover {
    border: 2px solid #f36196;
}

.channel-v2-content {
    display: flex;
}

.channel-v2-secondary {
    width: 39%;
    display: flex;
    justify-content: right;
    margin-left: 10px;
}


.channel-v2-secondary-number {
    min-width: 250px;
    height: 40px;
    display: flex;
    background-color: #262626;
    border-radius: 3px;
    align-items: center;

}

.channel-v2-secondary-number-purchase {
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 5px;
    background-color: #f36196;
    border: none;
    color: rgb(255, 255, 255);
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    transition: 1s all;
    position: relative;
}




/*BOTTOM */


.channel-bottom-v2-info {
    min-height: 100px;
    display: flex;
    justify-content: space-between;
}


.channel-bottom-v2-primary {
    display: flex;
    width: 55%;
}

.channel-bottom-v2-secondary {
    display: flex;
    justify-content: right;
    align-items: center;
}


.channel-bottom-v2-button-follow {
    width: 80px;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #f36196;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
}

.channel-bottom-v2-button-follow:hover {
    background-color: #ff50a6;
}



.channel-bottom-v2-button-sub {
    width: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #4e2232;
    border: 1px solid #a9a9a94a;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.channel-bottom-v2-button-sub:hover {
    background-color: #ff50a6;
}

.channel-bottom-v2-button-gestion {
    margin-top: 20px;
    width: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #262626;
    border: none;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.channel-bottom-v2-button-gestion:hover {
    background-color: #303030;
}

.channel-bottom-v2-button-icon {

    background-color: transparent;
    color: #ededed;
    cursor: pointer;
    border-radius: 3px;
    margin-left: initial;
}

.channel-bottom-v2-button-icon:hover {
    background-color: #373737;
}

.channel-bottom-v2-button-icon:hover i {
    transition: 0.3s;
}

.channel-bottom-avatar-text a {
    color: darkgray;
}

.channel-bottom-avatar-text a:hover {
    filter: brightness(69%);
}

.elapsedTime {
    display: flex;
    white-space: nowrap;
    font-weight: 500;
}

/* Media Screen for 1366x768 */
@media screen and (max-width: 1366px) {

    .channel-body {
        /* width: 100%; */
        height: 1200px;
        min-height: 1200px;
        overflow-x: hidden;
    }



    .channel-bottom-v2-info {
        /* width: 100%; */
        min-height: 100px;
        /* margin-top: 23px; */
        display: flex;
        justify-content: space-around;

    }

    .channel-v2-secondary {
        width: 43%;
        display: flex;
        /* justify-content: right; */
        margin-left: 10px;
    }
}

@media screen and (max-width: 768px) {
    .channel-body {
        width: 100%;
        height: 100%;
        
        overflow: hidden;
    }


}

.conteiner-streamer-online-infoStream {
    width: 100%;
    display: flex;
    background: #000000;
    border-radius: 0px 0px 10px 10px;
    
}