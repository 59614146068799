.clipcreate-body {
    width: 100%;
    min-height: 1000px;
    background-color: #0A0B0D;
}

.player-container-ch {
    width: 70%;
}

#create_clip_container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

#create_clip_videoUrl {
    display: flex;
    margin: 20px;
    flex-direction: column;
    align-items: center;
}


.create-clip-video {
    display: flex;
}

#clipTitle {
    width: 100%;
    height: 41px;
    border-radius: 6px;
    background-color: #19191d;
    font-size: 15px;
    font-family: inherit;
    box-shadow: 0 0 0 #0A0B0D;
    color: #ffff;
    padding: 0px 3px;
}

.create_clip_video>video {
    width: 100%;

    -webkit-appearance: none;
}

.create_clip_video video::-webkit-media-controls {
    display: flex !important;
    opacity: 1 !important;
    width: 100% !important;
}

.create_clip_video video::-webkit-media-controls-play-button,
.create_clip_video video::-webkit-media-controls-start-playback-button,
.create_clip_video video::-webkit-media-controls-mute-button,
.create_clip_video video::-webkit-media-controls-timeline {
    display: flex !important;
}

.create_clip_video video::-webkit-media-controls-timeline,
.create_clip_video video::-webkit-media-controls-current-time-display,
.create_clip_video video::-webkit-media-controls-time-remaining-display,
.create_clip_video video::-webkit-media-controls-volume-slider {
    display: flex !important;
}

.create_clip_video video::-webkit-media-controls-enclosure {
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 5px !important;
}

.create_clip_video video::-webkit-media-controls-panel {
    border-radius: 5px !important;
}

.create_clip_video video::-webkit-media-controls-play-button,
.create_clip_video video::-webkit-media-controls-timeline,
.create_clip_video video::-webkit-media-controls-volume-slider {
    margin: 0 8px !important;
}

.create_clip_video video::-webkit-media-controls-mute-button {
    margin-right: 8px !important;
}

.create_clip_video video::-webkit-media-controls-timeline {
    flex-grow: 1 !important;
}

.slider-container {
    display: flex;
    align-items: center;
    height: 50px;
}

.player-container {
    display: flex;
    padding: 1rem 9rem;
    /* width: 83%; */
    /* height: 500px; */
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.controls-container {
    width: -webkit-fill-available;
    /* display: flex;    */
}

.edit-clip {
    display: flex;
    align-items: center;
}

.edit-clip>button {
    font-weight: bolder;
}

#create_clip_videoUrl_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 200px;
}

.loading-spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#create_clip_videoUrl_loading p {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bolder;
    color: #555;
}

.input_title_clip {
    background: #303539;
    border-radius: 3px;
    margin: 20px 0px;
    margin-bottom: 10px;
    padding-left: 7px;
}

.remaining_characters_clip {
    color: #ffffff;
    font-size: 13px;
    font-weight: bolder;
    font-family: 'Montserrat';

}

#loading-progress-bar {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: center;
}

.progress-bar-container {
    width: 100%;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.progress-bar {
    height: 6px;
    background-color: #ff69c4;
    border-radius: 5px;
    transition: width 0.3s ease;
}

#loading-progress-bar span {
    color: #ffffff;
    font-weight: bolder;
    font-family: "inter";
    padding-left: 10px;
}

/* slider */

.slider-container {
    user-select: none;
    cursor: pointer;
}

.slider-container .rc-slider-track {
    height: 33px;
    background-color: #510b3f;
    border-radius: 6px;
    border: 2px solid #121418;
    user-select: none;
    cursor: pointer;
}

.slider-container .rc-slider-dot {
    display: none;
}

.slider-container .rc-slider-rail {
    height: 33px;
    width: 100%;
    background-color: #2b0120;
    border-radius: 6px;
}

.slider-container .rc-slider-handle {
    background-color: #f36196;
    border: none;
    width: 14px;
    height: 33px;
    border-radius: 10px;
    cursor: pointer;
    margin: 0px;
    opacity: 1;
    pointer-events: none;
    pointer-events: none;
    user-select: none;
}

.slider-container .rc-slider-handle-1 {
    background-color: #f36196;
    height: 33px;
    border-radius: 11px 0px 0px 11px;
}

/* Estilo para el segundo manejador */
.slider-container .rc-slider-handle-2 {
    border-radius: 0px 11px 11px 0px;
    background-color: #f36196;
    height: 33px;
}

.slider-container .rc-slider-handle-1::after,
.slider-container .rc-slider-handle-2::after {
    content: '';
    width: 16px;
    height: 20px;
    display: inline-block;
    /* background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20"%3E%3Cpath d="M12.5 11a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm0-10a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-5 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM6 7.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zM12.5 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM6 12.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zm5 5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zM7.5 16a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" fill="%23f36196"/%3E%3C/svg%3E'); */
    background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20"%3E%3Cpath d="M12.5 11a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm0-10a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-5 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM6 7.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zM12.5 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM6 12.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zm5 5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zM7.5 16a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" fill="%23000"/%3E%3C/svg%3E');

    background-size: 20px 20px;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
}


.slider-container .rc-slider-handle:active {
    /* background-color: #80b3f2; */
}

.slider-container .rc-slider-handle:hover {
    /* background-color: #80b3f2; */
}


.slider-container .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    /* border-color: none !important; */
    box-shadow: none !important;
}


.slider-container .slider-mark {
    display: inline-block;
    width: 4px;
    height: 24px;
    background-color: #582456;
    position: absolute;
    top: -5px;
    transform: translateX(-50%);
    border-radius: 10px;
    pointer-events: none;
    user-select: none;
}


.CrearClip-Playing {
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    color: #ededed;
    height: 50px;
    justify-content: center;
}

.CrearClip-PlayingCustomPlayer-primary {
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    color: #ededed;
    height: 50px;
    justify-content: center;

}