.container-streammanager {
    padding: 5rem 5rem;
    background: #080808;
}

.channel-chat {
    position: sticky;
    /* Asegúrate de que el contenedor tenga posición relativa */
    top: 80px;
    z-index: 888;
}

.streammanager-body {
    width: 100%;
    background-color: #0a0b0d;
    display: flex;
    height: 917px;
    align-items: "center";
    font-family: 'Montserrat';
}


.streammanager-content-1 {
    width: 25%;
    height: 917px;
    background-color: #040404;
}

.streammanager-content-2 {
    width: 54%;
    background-color: #040404;
    height: 914px;
    border: 1px solid #2b2b2b8f;
}


.streammanager-content-3 {
    width: 21%;
    height: 914px;
    overflow: hidden;
    border: 1px solid #2b2b2b8f;
}


@media screen and (max-width: 768px) {
    .streammanager-content-1 {
        width: 100%;
        height: 250px;
        background-color: #040404;
    }

    .streammanager-content-2 {
        width: 100%;
        background-color: #040404;
        height: 914px;
        border: 1px solid #2b2b2b8f;
    }


    .streammanager-content-3 {
        width: 100%;
        height: 914px;
        overflow: hidden;
        border: 1px solid #2b2b2b8f;
    }

}

.button-copy {
    margin: initial;
}

@media screen and (max-width: 768px) {

    .channel-chat {
        height: -webkit-fill-available;
        position: fixed;
        right: 0;
        background-color: #0c0c0c;
        transition: 0.15s;
        overflow-y: unset;
        z-index: 8888;
    }
}