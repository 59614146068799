
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdowngiftconfirm-menu {
    position: absolute;
    width: 420px;
    height: 650px;
    display: flex;
    background: #18181b;
    box-shadow: 0px 5px 20px 5px black;
    border-radius: 5px;
    z-index: 2000;
    }
    
    .dropdowngiftconfirm-menu li {
      cursor: pointer;
      display: flex;
      text-align: center;
      font-size: 12px;
      border-radius: 5px;
    }
    
    .dropdowngiftconfirm-menu i {
      transition: 0.3s all;

    }

    .dropdowngiftconfirm-menu li:hover .dropdowngiftconfirm-content i{
      transform: translateY(-3px);
    }

    .dropdowngiftconfirm-menu li:hover {
      background: #0e0e0e;
    }
    
    .dropdowngiftconfirm-menu.clicked {
      display: none;
    }
    
    .dropdowngiftconfirm-link {
      display: flex;
      height: 100%;
      width: 100%;
      text-decoration: none;
      color: #fff;
      padding: 12px;
    }

    .dropdowngiftconfirm-button {
      background: none;
      border: none;
      width: 80%;
      font-family: 'Montserrat';
      font-weight: 800;
      height: 100%;
      padding: 16px;
      color: #ededed;
      cursor: pointer;

    }

    .dropdowngiftconfirm-content {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      justify-content: space-between;
      color: #ededed;
      font-family: 'Montserrat';
      font-weight: 800;
    }




    .dropdowngiftconfirm-radio input[type="radio"] {
      background-color: rgb(105, 105, 105);
      border-radius: 10px;
      cursor: pointer;
      display: inline-block;
      height: 15px;
      margin-right: 5px;
      position: relative;
      width: 15px;
      -webkit-appearance: none;
  }
  .dropdowngiftconfirm-radio input[type="radio"]:after {
      border-radius: 25px;
      background-color: rgb(71, 71, 71);
      content: '';
      display: block;
      height: 9px;
      left: 3px;
      position: relative;
      top: 3px;
      width: 9px;
  }
  .dropdowngiftconfirm-radio input[type="radio"]:checked:after {
      background-color: #ff60b2;
  }

  .dropdowngiftconfirm-streamer {
    display: flex;
  }

  .dropdowngiftconfirm-button-sub {
    margin-top: 20px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background-color: #9147ff;
    border: none;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  .dropdowngiftconfirm-button-sub:hover {
    background-color: #5b12c9;
  }

  .dropdowngiftconfirm-total {
    width: 100%;
    height: 375px;
  }

    
    @media screen and (max-width: 960px) {
  
      .dropdowngiftconfirm-menu {
        width: 220%;
        top: 60px;
        z-index: 200;
  
      }
  
      .fa-caret-down {
        display: none;
      }
    }