.custom-player {
  width: 100%;
  background-color: transparent;
}

.custom-player-popup {
  position: fixed;
  top: 760px;
  width: 15%;
  height: 160px;
  transition: 0.15s;
}

.customPlayer-container {
  width: 100%;
  opacity: 0;
  height: 0px;
  position: relative;
  z-index: 9999;
}

.dropdownsettings-FullScreen {
  position: fixed;
  left: 55%;
  /* top: 49%;   */
  background: inherit;
}

.conteiner-playerFlv-Stream {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.channel-chat-fullscreen {
  height: 105%;
  position: relative;
  right: 0;
  width: 500px;
  background-color: #080808;
  transition: 0.15s;
  overflow-y: unset;
  z-index: 8888;
  top: 44px;

}

.customPlayer-primary {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  color: #ededed;
  height: 50px;
  justify-content: center;
  position: relative;
  top: -64px;
  background: linear-gradient(0deg, #000 0, rgba(0, 0, 0, .5) 60%, transparent);
}


.customPlayer-secundary-div {
  width: 50%;
  color: #ededed;
  display: flex;
  justify-content: left;
  align-items: center;
}


.customPlayer-secundary-div2 {
  width: 100%;
  color: #ededed;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 5px;
}

.customPlayer-card {
  z-index: 30;
}

.custom-player:hover .customPlayer-container {
  opacity: 1;
}

.custom-player-popup:hover .customPlayer-container {
  opacity: 1;
}

.customPlayer-shadow-1 {
  opacity: 0;
  width: 95%;
  margin-top: -37px;
  box-shadow: 5px 5px 50px 20px black;
  position: relative;
  transition: 0.5s all;
  position: relative;
  top: -25px;
  overflow: hidden;

}

.custom-player:hover .customPlayer-shadow-1 {
  opacity: 0;
}

.custom-player-popup:hover .customPlayer-shadow-1 {
  opacity: 1;
}


/* TOP */
.customPlayer-top {
  position: relative;
  top: 60px;
  left: 30px;
  width: 95%;
  height: 0px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;
  opacity: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.74);
}

.channel-custom-player-online {
  position: relative;
  top: 20px;
  background-color: rgb(249, 24, 128);
  color: #ededed;
  font-size: 12px;
  border-radius: 5px;
  width: 70px;
  padding: 3px;
  height: 15px;
  z-index: 500;

}

.customPlayer-close-popup {
  position: relative;
  top: 20px;
  color: #ededed;
  font-size: 12px;
  border-radius: 5px;
  width: 20px;
  padding: 3px;
  height: 15px;
  z-index: 1;
  cursor: pointer;
  margin-left: 10px;
}

.customPlayer-close-popup:hover {
  background-color: #303030;
}


.custom-player:hover .customPlayer-top {
  opacity: 1;
}

.custom-player-popup:hover .customPlayer-top {
  opacity: 1;
}

.custom-player-popup-icon {
  padding: 5px;
  border-radius: 3px;
}

.custom-player-popup-icon:hover {
  background-color: #303030;
}

.custom-player video {
  position: relative;
  background-color: transparent;
  z-index: 10;
  width: 98% !important;
  background: #000000a2;
}

.customPlayer-sound {
  background-color: transparent;
  width: 81.2%;
  /* height: 850px; */
  height: 450px;
  z-index: 10;
  position: absolute;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: white;
}

.pinkker-player-loading {
  position: absolute;
  top: 83px;
  left: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 521px;
  width: 67%;
  background-color: transparent;
  z-index: 999;
}


@media screen and (max-width: 768px) {
  .custom-player {
    margin-top: 30px;

  }

  .custom-player video {
    position: relative;
    background-color: transparent;
    z-index: 10;
    width: 100% !important;
    height: -webkit-fill-available;
    position: relative;
    background-color: transparent;
    z-index: 10;
    /* width: 100%; */
    /* width: 0%; */
    height: 100% !important;
  }

  .customPlayer-top {
    position: relative;
    top: 111px;
    left: 13px;
    width: 90% !important;
    height: 0px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    margin-top: -20px;
    opacity: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.74);
  }

  .customPlayer-primary {

    width: 93% !important;
  }

  .customPlayer-container {
    width: 100% !important;
  }

  .customPlayer-sound {
    width: 100%;
    height: 400px;
  }
}

.customPlayer-slider-vod {
  width: 100%;
}

.InLiveCustomPlayer {
  display: flex;
  color: darkgray;
  align-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

#LiveCustomPlayerSpan {
  width: 7px;
  height: 7px;
  background-color: #ff86e4;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 7px;
  animation: LiveCustomPlayerSpanAnimation 1s infinite ease-out;
}

@keyframes LiveCustomPlayerSpanAnimation {
  0% {
    box-shadow: 0 0 ff86e4;
  }

  30% {
    box-shadow: 0 0 2px 2px #ff86e4
  }

  70% {
    box-shadow: 0 0 2px 2px #ff86e4;
  }
}

#LiveCustomPlayerSpanNolive {
  width: 7px;
  height: 7px;
  background-color: #2f2d2f;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 7px;
  animation: LiveCustomPlayerSpanAnimationNolive 1s infinite ease-out;
}

@keyframes LiveCustomPlayerSpanAnimatioNolive {
  0% {
    box-shadow: 0 0 2f2d2f;
  }

  30% {
    box-shadow: 0 0 2px 2px #2f2d2f
  }

  70% {
    box-shadow: 0 0 2px 2px #2f2d2f;
  }
}