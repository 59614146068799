.message-body {
    /* width: 70%; */
    /* left: 57%; */
    /* height: 35px; */
    margin: 0 auto;
    /* position: fixed; */
    top: 400px;
    background-size: cover;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: column;
    gap: 15px;
}

.ContNewChat {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 20px;
    right:  20px;
    width: 45px;
}

.avatar-selectedUser {
    width: 29px;
    height: 30px;
    border-radius: 20px;
    position: relative;
    object-fit: cover;
}

.MessageChatContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 3px;
    
}

.search-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: none;
}

.message-bodysearch-input {
    display: flex;
    position: none;
    /* flex: 1 1; */
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}

.loader {
    margin-left: 10px;
}

.user-info {

    display: flex;
    margin-top: 10px;
    color: #fff;
    font-weight: bolder;
    font-family: "inter";
    align-items: center;
    cursor: pointer;
}

.user-info>p {
    padding: 10px;
}

.open-chat-button {
    background-color: #ff60b2;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.open-chat-button:hover {
    background-color: #e0559d;
}

.message-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
}

.message-input {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
}

.send-button {
    background-color: #ff60b2;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.send-button:hover {
    background-color: #e0559d;
}

.underline {
    content: '';
    position: absolute;
    bottom: -2px;
    /* height: 2px; */
    width: 32%;
    background-color: black;
    transition: left 0.3s ease;
    border: 1px solid #ff60b2;
}

.typesofchats {
    display: flex;
    justify-content: space-around;
    position: relative;
    border-bottom: 1px solid #ccc;
}

.typesofchats {
    display: flex;
    justify-content: space-around;
    position: relative;
    border-bottom: 1px solid #471f2e;
    width: 95%;
    gap: 10px;
    padding: 5px;

}

.typesofchats span {
    padding: 10px 10px;
    cursor: pointer;
    font-size: 14px;
    color: #ffffff;
    position: relative;
    font-weight: bold;

}

.typesofchats span.active {
    font-weight: bold;
}

.typesofchats span:hover {
    /* color: black; */
}


.typesofchats span:nth-child(1).active~.underline {
    left: 0;
}

.typesofchats span:nth-child(2).active~.underline {
    left: calc(33%);
}

.typesofchats span:nth-child(3).active~.underline {
    left: calc(68%);
}

.underline {
    left: 0;
}

@media screen and (max-width: 768px) {
    .message-body{
        width: 100% !important;
    }
}