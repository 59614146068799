.addemote-popup-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 9999;
   
}

.addemote-popup-container {
    width: 35%;
    height: 500px;
    overflow: scroll;
	margin: auto;
    padding: 30px;
	background: #18181b;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
}


.addmote-popup-content {
    width: 100%;
    height: 450px;
    margin-top: 5px;
    color: white;
}

.addmote-popup-file {
    width: 170px;
    height: 170px;
    opacity: 0;
    position: absolute;
}

.addemote-popup-input {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.addemote-popup-input input {
    padding: 7px;
    background-color: #303030;
    border-radius: 3px;
    margin-left: 5px;
    outline: none;
    font-size: 15px;
    color: white;
    border: none;
}

.addemote-popup-button {
    width: 100px;
    text-align: center;
    color: white;
    font-family: "Montserrat";
    border: none;
    border-radius: 5px;
    background-color: #ff60b2;
    padding: 10px;
    margin-top: 35px;
    margin-right: 10px;
}