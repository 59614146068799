.donationcard-body-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.donationcard-body {
    width: 100%;
    height: 90px;
    position: absolute;
    top: 110px;
    border-radius: 5px;
    cursor: move;
    z-index: 99999;
    padding-bottom: 10px;
    border: 1px solid #ffffff1a;

}


.donationcard-close {
    width: 95%;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: right;
}



.donationcard-container {
    width: 80%;
    position: relative;
    top: -10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.donationcard-primary {
    display: flex;
    align-items: center;
    height: 75px;
}


.donationcard-button {
    border: none;
    background-color: #f36196;
    color: #ededed;
    font-family: "Poppins";
    padding: 5px;
    width: 100%;
    border-end-start-radius: 3px;
    border-end-end-radius: 3px;
    cursor: pointer;
    margin-top: 15px;
}

.donationcard-button:hover {
    background-color: #bd3b7e;
}