@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

/* Theme Dark */


.navbar-dark {
  background-color: #080808;
  background-size: cover;
  height: 3.6rem;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  border-bottom: 1px solid #2a2e38;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  z-index: 1000000000000000;
  transition: 0.1s ease-in-out;
  box-shadow: 0.5px 0.5px 8px #000000;
  top: 0px;
  /* left: 220px; */
}

.navbar-logo-new {
  object-fit: none;
  object-position: 41% 30%;
  width: 35px;
  height: 32px;
  border-radius: 100px;
}


.navbar-dark.active {
  background-color: #210569dc;

}

.navbar-logo-dark {
  color: rgb(255, 255, 255);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  margin-left: 15px;
}

.navbar-logo-dark img {
  transition: 0.5s;
}




.pulse {
  border: none;
  width: 35px;
  height: 30px;
  color: #FFF;
  text-align: center;
  position: absolute;
  left: 13px;
  top: 11px;
  text-transform: uppercase;
  border-radius: 50%;
  animation: pulse 1s linear infinite;
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(156, 156, 156, 0.8), 0 0 0 0 rgba(167, 167, 167, 0.5)
  }

  40% {
    box-shadow: 0 0 0 10px rgba(201, 8, 95, 0), 0 0 0 5px rgba(201, 8, 95, 0)
  }

  80% {
    box-shadow: 0 0 0 10px rgba(201, 8, 95, 0), 0 0 0 5px rgba(201, 8, 95, 0)
  }

  100% {
    box-shadow: 0 0 0 0 rgba(201, 8, 95, 0), 0 0 0 10px rgba(201, 8, 95, 0)
  }
}




.nav-menu {
  display: flex;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  position: relative;
  z-index: 100000000000000000000;
}

.nav-item-dark {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 2px solid transparent;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 15px;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  margin-left: -7px;
  margin-top: 2px;

}

.nav-item-dark i {
  color: #ededed;

}

.nav-item-dark img {
  cursor: pointer;
}


.nav-links {
  color: #ededed;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #ffffff;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}



.navbar-avatar {
  background-color: #ededed;
  width: 40px;
  margin-right: 5px;
  border-radius: 30px;
  color: #ededed;
}

.navbar-divcoins {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.navbar-coins {
  display: inline-flex;
  width: 40px;
  color: #ededed;
}


.button-navbar-login {
  display: flex;
  align-items: center;
  text-shadow: rgb(0, 0, 0) 0px 1px 0px;
  justify-content: center;
  background-color: transparent;
  border: 1px solid rgb(42, 46, 56);
  background: rgb(42, 46, 56);
  text-shadow: '0 1px 0 #000';
  padding: 0px 1rem;
  height: 3rem;
  border-radius: 0.375rem;
  width: 100%;
  color: #EFEFF1;
  text-transform: capitalize;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.button-navbar-login:hover {
  background: #343843;
}

.button-purchase-pixels {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  /* border: 1px solid #262626; */
  border: 1px solid #471f2e;
  padding: 6.5px;
  height: 28px;
  border-radius: 5px;
  width: 150px;
  margin: 0px;
  margin-top: 0px;
  color: #EFEFF1;
  text-transform: capitalize;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  transition: 200ms all;
}

.boton-comprar {
  border-radius: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  border: 1px solid #471f2e;
}

.boton-comprar:hover {
  background-color: #471f2e !important;
  border: 1px solid #471f2e !important;
}

.button-purchase-pixels:hover {
  background-color: #471f2e;
  border: 1px solid #4c0b39;
  /* border: 1px solid #7717ff; */
}

.button-purchase-pixels>button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #2a2e38;
  padding: 6.5px;
  height: 45px;
  border-radius: 3px;
  width: 250px;
  letter-spacing: 0.5px;
  font-family: "inter";
  font-weight: 500;
  border: none;
}

.button-purchase-pixels>i {
  display: flex !important;
  width: 18px !important;
  height: 18px;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  font-size: 13px;
  cursor: pointer;
  transform: rotate(-90deg);
  align-content: center;
  align-items: center;
  justify-content: center;
  color: #594e99;
  animation: 1s;
}


.button-navbar-register {
  background-color: #ff50a6
  ;
  border: 1px solid #ff50a6;
  display: flex;
  text-shadow: rgb(0, 0, 0) 0px 1px 0px;
  align-items: center;
  justify-content: center;
  padding: 0px 1rem;
  text-shadow: '0 1px 0 #000';
  height: 3rem;
  border-radius: 0.375rem;
  color: #ededed;
  width: 100%;
  text-transform: capitalize;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;

}

.button-navbar-register:hover {
  background-color: #ff86e4;
}
.icono-sociales:hover {
  color: #ff86e4 !important;
}

.navbar-userlink {
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 5000px;
}

.navbar-userlink img {
  height: 34px;
  width: 35px;
  padding: 1px;
  transition: 0.1s;
}

.navbar-userlink img:hover {
  padding: 1px;
}

.navbar-coins {
  background-color: rgba(34, 34, 34, 0.384);
  min-width: 110px;
  border-radius: 3px;
  height: 25px;
  display: flex;
  align-items: center;
  padding: 3px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.navbar-coins-number {
  display: flex;
  align-items: center;
  margin-left: 5px;
}



.navbar-coins-purchase {
  width: 70px;
  border-radius: 3px;
  padding: 5px;
  margin-left: 10px;
  background-color: #f36196;
  border: none;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}


.navbar-image-avatar-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-image-avatar {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #100b0d;
  margin-right: 5px;

}

.navbar-image-avatar:hover {
  background-color: #f3619645;
}

.navbar-image-avatar img {
  width: 38px;
  height: 38px;
  border-radius: 20px;
  position: relative;
  object-fit: cover;
}


/*.navbar-image-avatar:hover {
  height: 42px;
  width: 42px;
}*/











/* Theme Light */


.navbar-light {
  background-color: #ffffff;
  background-size: cover;
  height: 52px;
  display: flex;
  justify-content: left;
  position: fixed;
  border-bottom: 2px solid #2b2b2b8f;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  z-index: 1000;
  transition: 0.1s ease-in-out;
}

.navbar-logo-light {
  color: rgb(255, 255, 255);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  margin-left: 20px;
}

.navbar-logo-light i {
  color: black;
}




.nav-item-light {
  display: flex;
  align-items: center;
  height: 80px;
  position: relative;
  height: 30px;
  border-bottom: 2px solid transparent;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 15px;
  width: 45px;
  justify-content: center;
  text-align: center;
  border-radius: 5px;

}

.nav-item-light i {
  color: rgb(0, 0, 0);

}

.nav-item-light img {
  border: 2px solid violet;
}


/* Theme Blue */


.navbar-blue {
  background-color: #0e1720;
  background-size: cover;
  height: 52px;
  display: flex;
  justify-content: left;
  position: fixed;
  border-bottom: 2px solid #2b2b2b8f;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  z-index: 1000;
  transition: 0.1s ease-in-out;
}

.navbar-logo-blue {
  color: rgb(255, 255, 255);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  margin-left: 20px;
}

.navbar-logo-blue i {
  color: rgb(255, 255, 255);
}



.nav-item-blue {
  display: flex;
  align-items: center;
  height: 80px;
  position: relative;
  height: 30px;
  border-bottom: 2px solid transparent;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 15px;
  width: 45px;
  justify-content: center;
  text-align: center;
  border-radius: 5px;

}

.nav-item-blue i {
  color: rgb(255, 255, 255);

}

.nav-item-blue img {
  border: 2px solid violet;
}

.navbar-numbers {
  height: 8px;
  min-width: 8px;
  max-width: 19px;
  background-color: #c00;
  color: #ededed;
  position: absolute;
  left: 15px;
  top: -4px;
  padding: 4px;
  border-radius: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #0a0a0a;
}

.navbar-numbers-two {
  height: 12px;
  min-width: 20px;
  max-width: 23px;
  background-color: #e51a4c;
  color: #ededed;
  position: absolute;
  left: 15px;
  top: -7px;
  padding: 4px;
  border-radius: 30px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #18181b;

}




@media screen and (max-width: 758px) {

  .navbar-dark {
    height: 75px;
    margin-bottom: 50px;
  }


  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    width: 70%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  .navbar-image-avatar img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .button-purchase-pixels {
    width: 180px;
    height: 2rem !important;
  }

  .boton-comprar {
    height: 100% !important;
  }

  .nav-item-dark {
    margin: 5px;
    width: 100%;
    color: #ededed;
  }

  .nav-item-dark i {
    font-size: 32px;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    background-color: rgb(51, 51, 51);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .navbar-img {
    width: 60px;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    position: relative;
  }


}