.message-chat-body {
    width: 96%;

    background-color: #100b0d;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid rgba(136, 136, 136, 0.226); */
    /* margin-left: 10px; */
    transition: 0.3s;
    padding: 5px;
}

.message-chat-body:hover {
    background-color: #4e2232;

}

.messagechat-closed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.messagechat-opened {
    display: flex;
    position: fixed;
    top: 3px;
    right: 0px;
    width: 75%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    z-index: 100000;
    background-image: url("../../../images/back-message.png");
    background-color: #080808;
    flex-direction: column;
    justify-content: space-between;
}
@media screen and (max-width: 768px) {
    .messagechat-opened {
        display: flex;
        position: fixed;
        
        right: 0px;
        width: 100%;
        height: 90%;
        background-position: center;
        background-size: cover;
        z-index: 100000;
        background-image: url("../../../images/back-message.png");
        flex-direction: column;
        justify-content: space-between;
    }
}
.ChatUserTo {
    color: rgb(237, 237, 237);
    margin: 0px 10px;
    font-weight: bolder;
    font-size: 24px;
}

.messagechat-opened-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #080808;
    border-bottom: 2px solid #2b2b2b3f;
    cursor: pointer;
    height: 20%;
    /* width: 24px; */
    height: 63px;
    padding: 3px 21px;

}

.messagechat-opened-messages {
    width: 100%;
    height: 93%;
    overflow: hidden;
    overflow-y: scroll;
}

.messagechat-opened-input {
    background-color: #26262c;
    width: 100%;
    display: flex;
    text-align: center;
    border-top: 1px solid #2b2b2b3f;
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 9px;
    align-items: center;
}


.messagechat-time {
    margin-left: 10px;
    position: relative;
    top: 8px;
    font-size: 10px;
    color: darkgray;
    font-weight: bolder;
}



.messagechat-date {
    display: flex;
    text-align: center;
    width: 100%;
    color: #fff;
    height: 30px;
    font-family: Inter;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
}

.messagechat-date>span {
    background: #182229;
    padding: 5px;
    border-radius: 10px;
    font-size: 12px;
}

.messagechat-closed-window {
    background-color: red;
}

.message-chat-body-window {
    width: 100%;
    height: 35px;
    background-color: #262626;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(136, 136, 136, 0.226);
    margin-left: 10px;
    transition: 0.3s;
    animation: 3s cambiarColor infinite;
}

.message-chat-send-input {
    background-color: #26262c;
    width: 100%;
    margin: 0 auto;
    border: none;
    padding: 7px;
    border-radius: 3px;
    color: white;
    outline: none;
    font-weight: 600;
}


.message-chat-send-input:focus {
    background-color: rgb(0, 0, 0);
    outline: 1px solid #ff60b2;
}

.message-chat-send-input::placeholder {
    color: rgb(255, 255, 255);
}

.messagechat-InfoUserTo {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 5px;
}

.navbar-image-avatar-messagechat>img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    object-fit: cover;
}

@keyframes cambiarColor {
    0% {
        background-color: #421440;
    }

    50% {
        background-color: #8a2185;
    }

    100% {
        background-color: #421440;
    }
}

.messagechat-InfoUserTo-noti {
    width: 7px;
    height: 7px;
    background-color: #ff00eb;
    border-radius: 50%;
    margin-top: 2px;
    margin-left: 5px;
    margin-right: 7px;
    animation: shadowPulseAnimationNoti 1s infinite ease-out;
}

@keyframes shadowPulseAnimationNoti {
    0% {
        box-shadow: 0 0 00e70166;
    }

    30% {
        box-shadow: 0 0 2px 2px #ff00eb
    }

    70% {
        box-shadow: 0 0 2px 2px #ff00eb;
    }
}