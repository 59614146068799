.page-wrapper {
    background-color: #0c0c0c;
    width: 100%;
    color: #ededed;
    border-left: 1px solid #ffffff1a;
    box-shadow: 0px 1px 1px 1px #0000004a;
    overflow: hidden;
    overflow-x: hidden;
    min-height: 1500px;
}

.button-open-hotel {
    background-color: #f36196;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;
    color: #ededed;
}



.chat-donation-body {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #080808;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #2a2e38;
}

.chat-donation-arrow {
    height: 51px;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

.chat-donation-arrow:hover {
    background-color: #151515;
}

.chat-donation-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* position: fixed; */
    width: 88%;
    justify-content: center;

}

.chat-donation-containerStateDonations {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 107px;
    width: 100%;
    justify-content: center;
    z-index: 100;
    background-color: #080808;
}

/* .chat-donation-card-container:hover {
    background-color: #151515;
} */

.chat-donation-card {
    width: 95px;
    height: 30px;
    border-radius: 10px;
    margin: 3px 10px;
    font-size: 14px;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    margin-right: 5px;
}

/* @keyframes closeCardDonation {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(50px);
        display: none;
    }
} */





.chat-donation-card-image {
    background-color: transparent;
    margin-right: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

}

.chat-donation-card-image img {
    object-fit: cover;
}

.chat-donatino-card-percent {
    position: absolute;
    height: 30px;
    border-top-left-radius: 10px;
    border-end-start-radius: 10px;
}


.chat-donation-card-text {
    background-color: transparent;
    color: rgb(255, 255, 255);
    height: 30px;
    width: 55px;
    border-top-right-radius: 10px;
    border-end-end-radius: 10px;
    text-align: center;
    display: flex;
    position: relative;
    left: -5px;

}

.chat-donation-card-triangulo {
    width: 1px;
    height: 1px;
    border-bottom: 5px solid #DBDBDB;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    position: relative;
    left: -8px;
    transform: rotate(270deg);
}

.getDonationSubscriptionCard-map-daughter {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 6px;
    border-radius: 10px;
    height: 20px;
    color: aliceblue;
}

@media screen and (max-width: 768px) {
    .page-wrapper {
        min-height: 670px;
        height: 670px;
    }

    .chat-donation-container {
        width: 96%;
        z-index: 100000;
    }

    .getDonationSubscriptionCard {
        width: 98% !important;
    }
}