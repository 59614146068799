.socialnetwork-body {
    display: flex;
    flex-direction: column;

}

.socialnetwork-container {
    width: 100%;
    background-color: #0404048f;
    border-radius: 5px;
    margin-top: 10px;
    height: 375px;
}



.socialnetwork-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 65px;
    background-color: #0404048f;
    margin-top: -1px;
}

.socialnetwork-input {
    width: 80%;
}


.socialnetwork-input input {
    width: 80%;
    background-color: #303030;
    border: none;
    padding: 10px;
    outline: none;
    color: white;
    border-end-end-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 600;
}

.socialnetwork-input input:focus {
    background-color: #000000;
    border: 1px solid #ff60b2;
}


.socialnetwork-button {
    padding: 7px;
    background-color: #ff60b2;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


@media screen and (max-width: 758px) {
    .socialnetwork-body {
        width: 100%;
    }
}

.mt-5 {
    margin-top: 1.25rem;
}

.rounded {
    border-radius: 4px;
}

.bg-secondary {
    background-color: rgb(25 27 31 / var(--tw-bg-opacity));
}

.border-b-2 {
    border-bottom-width: 2px;
}

.border-secondary-lighter {
    border-color: #e5e7eb;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.font-semibold {
    font-weight: 600;
}

.border-t-2 {
    border-top-width: 2px;
}

.p-4 {
    padding: 1rem;
}


.text-sm {
    font-size: 0.875rem;
}



.mb-1 {
    margin-bottom: 0.25rem;
}

.font-bold {
    font-weight: 700;
}

.text-gray-400 {
    color: #cbd2d9;
}




.shrink-0 {
    flex-shrink: 0;
}



.capitalize {
    text-transform: capitalize;
}



.socialnetwork-body-conteiner {
    padding: 10px;
    background-color: #191b1f;
    border-radius: 5px;
}

.content-commands {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    width: 100%;
    background-color: #191b1f;
}

.comandos-conteiner {
    display: flex;
    /* flex-direction: row; */
    width: 200px;
    color: #929ea6;
    background: #24272c;
    flex-direction: row;
    align-content: center;
    align-items: center;
    padding-left: 14px;
}

.user-red-delete-comando {
    padding: 0px;
}

.comando-input {
    display: flex;
    /* flex-direction: row; */
    color: #929ea6;
    background: #24272c;
    flex-direction: row;
    align-content: center;
    align-items: center;
    padding-left: 14px;
}

.comando-input button {
    font-weight: bolder;
}

.red-config {
    font-weight: bolder;
    width: 129px;
}

.user-red {
    background-color: #303539;
    border-radius: 0px;
    padding-left: 10px
}

.button-content-social {
    display: flex;
}

.button-content-social>button {
    font-weight: bolder;
    width: 100%;
    margin: 10px 0px;
}

.comandos-conteiner-txt {
    width: 100%;
}