.tweetcard-body {
    width: 97.8%;
    min-height: 75px;
    display: flex;
    padding: 10px;
    z-index: 1000;
    flex-direction: row;
}

.DropdownReTweet-main {
    position: absolute;
}

.tweetcard-line {
    position: relative;
    width: 4px;
    background-color: #313534;
    height: 100%;
    left: 30%;
    margin-top: 5%;
    z-index: 100;
    border-radius: 10px;
}

.Postcard-container {
    display: flex;
}

.tweetcard-container {
    width: 100%;
    min-height: 75px;
    gap: 10px;
    display: flex;
    z-index: 1000;
    cursor: pointer;

}

.tweetcard-open-tweet {
    position: absolute;
    height: 55px;
    width: 650px;
}

.tweetcard-body:hover {
    /* background-color: rgb(32, 35, 41); */
    border-radius: 5px;
}

.PostimageLinkPromocionado {
    position: absolute;
    left: 21px;
    top: 73%;
    background: #0000005e;
    padding: 4px;
    border-radius: 10px;
}

.PostLinkPromocionado {
    color: white;
}

.tweetcard-avatar {
    width: 50px;
    text-align: center;
}

.PostCommentsClipcard-avatar {
    width: 50px;
    text-align: center;
}

.tweetcard-primary {
    color: white;
    width: 100%;
    position: relative;
    left: 4px;
}

.hoverLink:hover {
    text-decoration-line: underline;
}

.tweetcard-icons {
    width: 80%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tweetcard-icon {
    color: lightgray;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tweetcard-icon-comment {
    color: lightgray;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tweetcard-icon-comment i {
    padding: 5px;
    border-radius: 50px;
    transition: 0.5s;

}

.tweetcard-icon-comment:hover i {
    background-color: rgba(46, 220, 255, 0.315);
}

.tweetcard-icon-comment:hover {
    color: rgb(46, 220, 255);
}


.tweetcard-icon-retweet {
    color: lightgray;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tweetcard-icon-retweet i {
    padding: 5px;
    border-radius: 50px;
    transition: 0.5s;

}

.tweetcard-icon-retweet:hover i {
    background-color: rgba(46, 255, 60, 0.315);

}


.tweetcard-icon-retweet:hover {
    color: rgb(46, 255, 60);
}

.tweetcard-icon-like {
    color: lightgray;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
}

.tweetcard-icon-like i {
    padding: 5px;
    border-radius: 50px;
    transition: 0.5s;

}

.tweetcard-icon-like:hover i {
    background-color: rgba(255, 46, 46, 0.315);
}



.tweetcard-icon-like:hover {
    color: rgb(255, 46, 46);
}

.tweetcard-icon-share {
    color: lightgray;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
}

.tweetcard-icon-share i {
    padding: 5px;
    border-radius: 50px;
    transition: 0.5s;

}

.tweetcard-icon-share:hover i {
    background-color: rgba(46, 238, 255, 0.315);
}


.tweetcard-icon-share:hover {
    color: rgb(46, 238, 255);
}


.like-btn-svg {
    width: 80px;
    height: 100px;
    position: absolute;
    left: 25px;
    bottom: 0;
    /* temp value */
    transform: translate(-50%, -50%);
    background: url(https://abs.twimg.com/a/1446542199/img/t1/web_heart_animation.png) no-repeat;
    background-position: 0 0;
    cursor: pointer;
}

.like-btn-svg.animate {
    transition: background 1s steps(28);
    animation: fave-like-btn-svg 1s steps(28);
    background-position: -2800px 0;
}

@keyframes fave-like-btn-svg {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -2800px 0;
    }
}

.tweetcard-citetweet {
    width: 95%;
    min-height: 75px;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
    border: 1px solid #ffffff1a;
    text-align: left;
    color: white;
}

.container-data-user-posts {
    width: 80%;
    display: flex;
    gap: 5px;
    align-items: flex-start;
    flex-direction: column;
}

@media screen and (max-width: 768px) {


    .Postcard-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .PostCommentsClipcard-avatar {
        display: flex;
        width: 100%;
        text-align: center;
    }

    .tweetcard-body {
        width: 97.8%;
        height: 100% !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;

    }

    .tweetcard-avatar {
        width: 100% !important;
        display: flex;
        text-align: center;
    }

    .PostCommentsClipcard {
        /* width: 100% !important; */
        display: flex;
        text-align: center;
    }

    .PostimageLinkPromocionado {
        position: absolute;
        top: 77%;
    }

    .tweetcard-container {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1000;
        cursor: pointer;

    }

    .tweetcard-icons {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 10px;
    }
}