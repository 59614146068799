.card-stream-map {
    width: 95%;
    display: flex;
    margin: 0px auto;
    flex-wrap: wrap;
}

.categorie-body-dark {
    width: 100%;
    background-color: #0A0B0D;
}

.categorie-container {
    width: 100%;
    margin: 0 auto;
}

.categorie-title {
    width: 100%;
    margin: 0 auto;
    height: 300px;
    display: flex;
    box-shadow: 0px 1px 140px 54px rgba(0, 0, 0, 0.75) inset;
    color: #ededed;
    background-size: cover;
}

.categorie-button-follow {
    width: 75px;
    padding: 7px;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid #f36196;
    color: #ededed;
    font-family: 'Montserrat';
    cursor: pointer;
}


.categorie-title-tag {
    background-color: transparent;
    border: 1px solid #262626;
    color: rgb(204, 204, 204);
    border-radius: 2px;
    padding: 6px;
    margin-top: 5px;
    letter-spacing: 0.7px;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.4px;
    cursor: pointer;
    margin-right: 5px;
    font-family: ProximaNova, Arial, Tahoma, PingFangSC, sans-serif;
}


.categorie-title-tag:hover {
    background-color: #1c1d1f;
}


.categorie-set-type {
    width: 95%;
    font-family: 'Montserrat';
    display: flex;
    padding: 0px;
    margin: 0px auto;
    border-top: 1px solid rgb(41, 41, 41);
    justify-content: left;
}

.home-categories-card-contain-img {
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}

@media screen and (max-width: 768px) {
    .categorie-body-dark {
        width: 100%;
        min-height: 1500px;
        background-color: #0A0B0D;
        margin-top: 50px;
        padding: 3rem;
    }

    .card-stream-map {
        display: flex;
        margin: 0px auto;
        flex-wrap: wrap;
        justify-content: center;
    }

    .home-categories-card-container {
        /* width: 200px; */
    }

    .home-categories-card-contain-img {
        width: 100%;
    }

    .home-categories-card {
        max-width: 100%;
        /* min-width: 400px; */
        padding: 1rem 2rem
    }
}

.type-set {
    background: #f3619608;
    width: 339px;
    margin-left: 10px;
    border-radius: 10px;
}

/* .type-set :hover {
    background: #f36196;
} */