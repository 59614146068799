.tendency-body {
    width: 100%;
    min-height: 1000px;
    background-color: transparent;
}

.tendency-container {
    width: 95%;
    min-height: 1000px;
    margin: 0 auto;
    display: block;
    justify-content: left;
    position: relative;
    padding: 2rem 8rem;
    background: #080808;
}



.tendency-title {
    display: flex;
    align-items: center;
    color: #ededed;

}

.tendency-card-container {
    margin-top: 100px;
}

.tendency-card-container-clips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tendency-card-container-streams {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 9rem;
}

.home-categories-p-1-name {
    font-size: 15px;
    font-family: "Inter";
    font-weight: "300";
    letter-spacing: "0.6px";
    margin-top: "3px";
    cursor: "pointer";
}

@media screen and (max-width: 758px) {
    .tendency-body {
        min-height: 1500px;
    }
}