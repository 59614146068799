/* PerformanceCard.css */
.card {
    background-color: #1f1f1f;
    padding: 20px;
    border-radius: 10px;
    color: white;
    text-align: center;
    width: 10%;
    margin: 10px;
  }
  
  .stat-number {
    margin: 10px 0;
    font-size: 2em;
    color: black;

  }
  
  .stat-title {
    margin: 0;
    font-size: 1em;
    color: black;
  }
  
  .chart-container {
    width: 100%;
    height: 100px;
  }
  