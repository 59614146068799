.userbanner-body {
    width: 50%;
    margin-top: 50px;
}

.userbanner-container {
    width:  100%;
    margin: 0 auto;
    background-color: rgb(22, 22, 22);
    border-radius: 5px;
    min-height: 150px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
}

.custom-file-input {
    color: transparent;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
      width: 30%;
      text-align: center;
    content: 'Actualizar';
    color: white;
    display: inline-block;
    background-color: rgb(37, 37, 37);
    border-radius: 5px;
    padding: 10px;
    outline: none;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 12px;
    margin-left: 80px;
    margin-top: 10px;
  }
  .custom-file-input:hover::before {
    background-color: rgb(54, 54, 54);
  }