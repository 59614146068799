.PostComunidad-conteiner {
    width: 82%;
    margin-left: 110px;
}

@media screen and (max-width: 768px) {
    .PostComunidad-conteiner {
        width: 100%;
        margin: 0px;
        padding: 0px !important;
        max-height: 85dvh !important;
    }
    .CommunityInfo{
        padding: 0px !important;
    }
}

.mod-comunidad-actions-container {
    display: flex;
    background-color: #080808;
    justify-content: flex-end;
    color: #fff;
}

.mod-comunidad-actions {
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
}

.mod-comunidad-actions:hover {
    background: #3d192c;
}

.information_for_member>p {
    font-size: 22px;
    color: #f36196;
}

.comunidad-info-set {
    width: 100%;
    margin: 0px 0px 0px 0px;
    margin: 24px 0px;

}

.type-set-comunidad {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    margin-left: 1rem;
    border-top: 1px solid rgb(42, 46, 56);
    background: #f3619608;
    margin-left: 10px;
    border-radius: 10px;
    align-items: center;
}

.type-post-comunidad.active {
    background: #f3619645;
}

.type-post-comunidad {
    /* margin: 7px; */
    border-radius: 7px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    width: 200px;
    height: 30px;
    cursor: pointer;
}