.conteiner-containerOAuth2Callback::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Overlay oscuro */
    z-index: -1;
}

.conteiner-containerOAuth2Callback {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("/public/images/imagen-signup-google.webp");

    background-size: cover;
    background-position: center top;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 15%;


}

.form-container {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    /* background-color: rgba(255, 255, 255, 0.9); */
    max-width: 400px;
    width: 40%;
    left: 7%;
    top: 241px;
}

.form-containerOAuth2Callback {
    display: flex;
    flex-direction: column;
    width: 100%;
}


label {
    display: block;

    input,
    textarea,
    select {
        width: 100%;
        padding: 8px;
        margin-top: 5px;
        box-sizing: border-box;
    }

    input[type="checkbox"] {
        margin-right: 5px;
    }

    div {
        display: flex;
        align-items: center;

        input[type="checkbox"] {
            margin-right: 5px;
        }
    }
}

label {
    color: white;
}

.auth-contentOAuth2callback {
    background-color: #414141;
    padding: 10px;
}



button {
    background-color: #f36196;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-left: auto;


}



.OAuth2Login {
    display: flex;
    justify-content: center;
    width: 100%;
}

.OAuth2Login-button {
    cursor: pointer;
    font-weight: 700;
    font-size: 0.875rem;
    color: #fff;
    border: none;
    width: 100%;
    text-align: center;
}

.form-containerOAuth2Callback>button {
    margin: 5px 8px 0px 9px;
}

/* Media query para pantallas más grandes */
@media (min-width: 768px) {
    .conteiner-containerOAuth2Callback {
        align-items: center;
        /* Centrar verticalmente el formulario en PC */
        padding-top: 0;
    }

    .form-container {
        top: -18%;
        left: 33px;
        width: 22vh;
    }

    .form-containerOAuth2Callback {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .conteiner-containerOAuth2Callback {
        padding-top: 10%;
        /* Mantener el comportamiento actual en móviles */
    }

    .form-container {
        padding: 10px;
        top: 15%;
        width: 19vh;
        left: 48px;
    }

}