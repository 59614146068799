.predictpopup-popup-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 9999;
}

.predictpopup-popup-container {
    width: 20%;
	height: 550px;
	position: absolute;
	left: 25%;
	right: 25%;
	top: 25%;
	bottom: 25%;
	margin: auto;
    padding: 30px;
	text-align: center;
	background: #18181b;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
    z-index: 9999;

}

.predictpopup-card {
    width: 100%;
    height: 125px;
    margin-top: 20px;
    background-color: rgb(27, 27, 27);
    border-radius: 5px;
    border: 1px solid rgb(39, 39, 39);
    color: violet;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;    
}


.predictpopup-card:hover {
    background-color: rgb(107, 107, 107);
}


.usersettings-popup-close {
    width: 100%;
    display: flex;
    justify-content: right;
}

.usersettings-popup-close button{
    background-color: transparent;
    border: none;
    color: #ededed;
    cursor: pointer;
}


.predictpopup-loadfile {
    width: 100%;
    background-color: red;
    height: 30%;
    top: 260px;
    left: 0;
    position: absolute;
    opacity: 0;
}


.usersettings-crop-container {
    position: relative;
    width: 100%;
    height: 300px;
    background-color: #333;

}


.usersettings-crop-input-container {
    width: 100%;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usersettings-crop-input-container input{
    width: 70%;
}


  .usersettings-popup-cancel {
      width: 75px;
      padding: 7px;
      background-color: #333;
      color: #ededed;
      border: none;
      border-radius: 5px;
      cursor: pointer;
  }

  .usersettings-popup-save {
    width: 75px;
    padding: 7px;
    background-color: #ff60b2;
    color: #ededed;
    border: none;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
}

.predictpopup-input {
    background-color: #303030;
    border: none;
    padding: 5px;
    border-radius: 3px;
    margin-top: 5px;
    width: 60%;
    color: white;
    font-weight: 600;
    outline: none;
}

.predictpopup-input:hover {
    outline: 1px solid rgb(92, 92, 92);
}

.predictpopup-input:focus {
    background-color: rgb(0, 0, 0);
    outline: 1px solid #ff60b2;
}

.predictpopup-button {
    background-color: #ff60b2;
    color: white;
    font-weight: 800;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 125px;
}

.predictpopup-button:hover {
    background-color: #bd3b7e;
}

.predictpopup-popup-result-card {
    background-color: #303030;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    border: 2px solid #151515;
}

.predictpopup-popup-result-card:hover {
    background-color: #151515;
    border: 2px solid #303030;
}

.predictpoup-popup-result-button-container {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 50px;
}

.predictpoup-popup-result-button {
    background-color: #ff60b2;
    color: white;
    font-weight: 800;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 150px; 
}