.channel-community-body {
    background-color: blue;
    width: 100%;
    min-height: 800px;
    padding-top: 50px;
}


.channel-community-container {
    background-color: rebeccapurple;
    width: 95%;
    margin: 0 auto;
    min-height: 600px;
}

.channel-community-profile {
    background-color: #161616;
    height: 440px;
    width: 400px;
    border-radius: 10px;
    padding: 10px;
}

.channel-community-profile-container {
    background-color: #262626;
    height: 440px;
    width: 400px;
    border-radius: 5px;
    padding-top: 5px;
}


.channel-community-profile-title {
    color: white;
    text-align: center;
}

.channel-comunnity-profile-content {
    width: 90%;
    margin: 0 auto;
    color: white;
}

.channel-community-profile-tags {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
}


/*BADGES*/


.channel-community-badges {
    background-color: #161616;
    height: 300px;
    width: 400px;
    border-radius: 10px;
    padding: 10px;
}

.channel-community-badges-container {
    background-color: #262626;
    height: 300px;
    width: 400px;
    border-radius: 5px;
    padding-top: 5px;
}


.channel-community-badges-title {
    color: white;
    text-align: center;
}

.channel-community-badges-card-container {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
}

.channel-community-badges-card-container {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
}


.channel-community-followers-card-container {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
}


.channel-community-comments-input {
    width: 90%;
    margin: 0 auto;
    color: white;
}

.channel-community-comments-content {
    height: 350px;
}

.channel-community-comments-input input {
    width: 96%;
    padding: 5px;
    border: none;
    border-radius: 3px;
    background-color: #161616;
    color: white;
    font-family: "Poppins";
}