@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdownnotifications-menu {
    position: absolute;
    top: 52px;
    right: 150px;
    display: flex;
    background: #0a0a0afc;
    border: 1px solid #ffffff1a;
    border-radius: 5px;
    font-family: 'Roboto';
    width: 350px;
    height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

.dropdownnotifications-menu li {
    display: flex;
    width: 100%;
    font-size: 12px;
    padding: 10px;
    cursor: pointer;
}

.dropdowns-title-container {
    display: flex;
    width: 100%;
    font-size: 12px;
    padding: 10px;
    height: 30px;
}


.dropdownnotifications-menu.clicked {
    display: none;
}

.dropdownnotifications-button {
    background-color: #f36196;
    border: none;
    margin-top: 20px;
    width: 50%;
    font-family: 'Montserrat';
    font-weight: 800;
    padding: 5px;
    color: #ededed;
    cursor: pointer;
    border-radius: 3px;

}

.dropdownnotifications-input {
    width: 100%;
    display: flex;
    align-items: center;
}

.dropdownnotifications-input input {
    width: 90%;
    background-color: #040404;
    border: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 5px;
    color: #ededed;
    outline: none;
    margin-left: 1px;
}

.dropdownnotifications-input i {
    background-color: #040404;
    padding: 7px;
    color: #ededed;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.dropdownnotifications-news-card {
    width: 100%;
    display: flex;
    align-items: center;
    color: #ededed;
    text-align: left;
}

.dropdownnotifications-menu li:hover {
    background-color: #161616;
}

.dropdownnotifications-button-follow {
    width: 50px;
    padding: 5px;
    background-color: #f36196;
    border: none;
    color: #ededed;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
}

@media screen and (max-width: 758px) {

    .dropdownnotifications-menu {
        width: 100%;
        right: 0;
        top: 63px;

    }

    .fa-caret-down {
        display: none;
    }
}