
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdownnews-menu {
    position: absolute;
    top: 52px;
    right: 300px;
    display: flex;
    background: #0a0a0afc;
    border: 1px solid #ffffff1a;
    box-shadow: 0px 5px 20px 5px black;
    border-radius: 5px;
    font-family: 'Roboto';
    width: 350px;
}
    
.dropdownnews-menu li {
  display: flex;
  width: 95%;
  font-size: 12px;
  border-bottom: 1px solid #4b4b4b8f;
  padding: 10px;
  cursor: pointer   ;
}
    

.dropdownnews-news-li:hover {
  background-color: #0e0e0e;
  
}
    
.dropdownnews-menu.clicked {
    display: none;
}
    
.dropdownnews-button {
      background-color: #f36196;
      border: none;
      margin-top: 20px;
      width: 50%;
      font-family: 'Montserrat';
      font-weight: 800;
      padding: 5px;
      color: #ededed;
      cursor: pointer;
      border-radius: 3px;

}

.dropdownnews-news-card {
    width: 100%;
}
    
@media screen and (max-width: 960px) {
  
      .dropdownnews-menu {
        width: 220%;
        top: 60px;
        z-index: 200;
  
      }
  
      .fa-caret-down {
        display: none;
      }
}