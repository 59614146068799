@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdownchatconfig-menu {
  position: absolute;
  margin-top: -245px;
  left: 0px;
  width: 93%;
  height: 193px;
  display: flex;
  background: #0a0a0afc;
  border: 1px solid #15151563;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
  border-bottom: 1px solid #ffffff1a;
  z-index: 9999;
}

.dropdownchatconfig-menu li {
  cursor: pointer;
  display: flex;
  text-align: center;
  font-size: 13px;
  border-radius: 5px;
}

.dropdownchatconfig-menu i {
  transition: 0.3s all;
}

.dropdownchatconfig-menu li:hover .dropdownchatconfig-content i {
  transform: translateY(-3px);
}

.dropdownchatconfig-menu li:hover {
  background: #2e2e2e;
}

.dropdownchatconfig-menu.clicked {
  display: none;
}

.dropdownchatconfig-link {
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 5px;
}

.dropdownchatconfig-button {
  background: none;
  border: none;
  width: 80%;
  font-family: 'Montserrat';
  font-weight: 800;
  height: 100%;
  padding: 16px;
  color: #ededed;
  cursor: pointer;

}

.dropdownchatconfig-content {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  color: #ededed;
  font-family: 'Montserrat';
  font-weight: 800;
}




.dropdownchatconfig-radio input[type="radio"] {
  background-color: rgb(105, 105, 105);
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  height: 15px;
  margin-right: 5px;
  position: relative;
  width: 15px;
  -webkit-appearance: none;
}

.dropdownchatconfig-radio input[type="radio"]:after {
  border-radius: 25px;
  background-color: rgb(71, 71, 71);
  content: '';
  display: block;
  height: 9px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 9px;
}

.dropdownchatconfig-radio input[type="radio"]:checked:after {
  background-color: #ff60b2;
}

@media screen and (max-width: 960px) {

  .dropdownchatconfig-menu {
    width: 100%;
    bottom: 25%;
    z-index: 9999;

  }

  .fa-caret-down {
    display: none;
  }
}