.streammanager-activity {
    width: 100%;
    height: 336px;
    background-color: #040404;
    border: 1px solid #2b2b2b8f;
}

.activity-card-follow {
    width: 100%;
    margin: 0 auto;
    height: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff1a;
}