/* Loading.css */

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100vh;
    width: 100%;
    background-color: #080808; /* Puedes ajustar el color de fondo según el diseño de tu aplicación */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999; /* Asegúrate de que esté en un z-index alto para que esté por encima de otros elementos */
    opacity: 1;
    transition: opacity 0.5s ease; /* Define la transición de opacidad */
}

.logo {
    width: 15%;
   
}


/* Dispositivos pequeños (teléfonos en modo vertical) */
@media (max-width: 575.98px) {
    /* Estilos para dispositivos pequeños */
    .logo {
        width: 50% !important;
       
    }
  }
  
  /* Dispositivos pequeños (teléfonos en modo horizontal) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    /* Estilos para dispositivos pequeños en modo horizontal */
    .logo {
        width: 50% !important;
        
        
    }
  }
  .rotate-animation {
    animation: rotate 1s infinite linear; /* Define la animación de giro */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading-container.fade-out {
    opacity: 0;
}
