@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

.chat-input {
  padding: 10px;
  padding-top: 25px;
  position: relative;
}

.input-container {
  border: 1px solid #15151563;
  padding: 5px;
  border-radius: 3px;
  background-color: transparent;
}

.chat-body-input {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
}

.chat-body-input i {
  padding: 3px;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
}

.chat-body-input i:hover {
  padding: 3px;
  background-color: #303030;
  border-radius: 5px;
}

.chat-send-input {
  border: none;
  min-width: 98%;
  max-width: 98%;
  font-family: Inter, Roobert, Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: transparent;
  font-weight: 400;
  color: darkgray;
  font-size: 14px;
  padding-bottom: 3px;
  outline: none;
  cursor: text;
  margin-top: 2px;
  margin-left: 2px;
  transition: 0.3s all;
  max-height: 40px;
  overflow-y: scroll;
}

.chat-send-input::placeholder {
  color: rgb(202, 202, 202);
}

.config-button {
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  color: #ededed;
  cursor: pointer;
}

.config-button:hover {
  background-color: #303030;

}

.send-button {
  color: #fff;
  background-color: transparent;
  border: 1px solid #f36196;
  display: inline-block;
  width: 65px;
  margin-right: 5px;
  padding: 2px;
  height: 27px;
  font-size: 12px;
  border-radius: 5px;
  letter-spacing: 0.2px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.send-button:hover {
  background-color: #762543;
}


.send-button-disabled {
  color: #fff;
  background-color: #151515;
  display: inline-block;
  border: none;
  width: 65px;
  margin-right: 5px;
  padding: 2px;
  height: 27px;
  font-size: 12px;
  border-radius: 5px;
  letter-spacing: 0.2px;
  font-family: "Montserrat", sans-serif;
  cursor: not-allowed;
}


.chat-delay-error {
  position: absolute;
  width: 340px;
  padding: 5px;
  top: -38px;
  border: 1px solid #ffffff1a;
  left: -10px;
  z-index: 1000;
  background-color: #18181bf8;
  text-align: center;
}

.chat-delay-error-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-none {
  background-color: transparent;
  border: none;
  color: #ededed;
  font-family: 'Poppins';
  margin-right: 5px;
}

.button-points {
  display: flex;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  border: none;
  color: #ededed;
  font-family: 'Poppins';
  padding: 3px;
  border-radius: 3px;
  margin: 0px 0px 0px 0px
}

.text-points-increment {
  width: 35px;
  border-radius: 5px;
  font-size: 12px;
  font-family: 'Poppins';
  margin-left: 5px;
  color: #f36196;
  padding: 3px;
  transition: 3s;
  animation: points-increment 3s infinite;
}

@keyframes points-increment {
  0% {
    transform: translateY(0px);
  }

  100% {
    filter: opacity(0.5);
    transform: translateY(-10px);

  }
}

.text-points-increment2 {
  width: 35px;
  border-radius: 5px;
  font-size: 12px;
  font-family: 'Poppins';
  margin-left: 5px;
  color: #ededed;
  background-color: #f36196;
  padding: 3px;
  transition: 3s;
}




/* V2 */



.chat-input-v2-container {
  height: 100px;
  width: 100%;
  position: relative;
  top: -150px;
}

.chat-input-v2-content {
  display: flex;
  align-items: center;
}

.chat-input-v2-bottom {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}



.chat-send-input:empty:before {
  content: attr(data-placeholder);
}


.input-emote-div {
  width: 25px;
  margin-right: 2px;
  position: relative;
  top: 5px;
  margin-left: 1.5px;

}

.chat-input-v2-text {
  position: absolute;
  right: 20px;
  top: -0px;
  font-size: 10px;
}


.chat-moderator-bar {
  background-color: rgb(56, 56, 56);
  width: 90%;
  height: 2px;
  margin: 0 auto;
  position: relative;
  top: 40px;
}

.chat-moderator-bar-content {
  background-color: rgb(255, 255, 255);
  height: 2px;
}


.input-user-hover {
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 3px;
}

.input-user-hover:hover {
  background-color: #303030;
  cursor: pointer;
}



@media screen and (max-width: 768px) {
  .chat-send-input {
    font-size: 24px;
    max-height: 60px;
  }

  .send-button {
    color: #fff;
    background-color: transparent;
    border: 1px solid #f36196;
    display: inline-block;
    width: 125px;
    margin-right: 5px;
    padding: 2px;
    height: 55px;
    font-size: 16px;
    border-radius: 5px;
    letter-spacing: 0.2px;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    position: relative;
    top: 5px;
  }

}