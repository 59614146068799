
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdownOptions-menu {
    position: absolute;
    margin-top: 15px;
    width: 180px;
    display: flex;
    background: #181818;
    box-shadow: 0px 5px 20px 5px black;
    border-radius: 5px;
    z-index: 99999;
}
    
    .dropdownOptions-menu li {
        width: 100%;
      cursor: pointer;
      display: flex;
      text-align: center;
      font-size: 12px;
      border-radius: 5px;
      padding: 7px;
      z-index: 99999;

}
    
    .dropdownOptions-menu i {
      transition: 0.3s all;
      z-index: 99999;

    }

    .dropdownOptions-menu li:hover .dropdownOptions-content i{
      transform: translateY(-3px);
      z-index: 99999;

    }

    .dropdownOptions-menu li:hover {
      background: #303030;
      z-index: 99999;

    }
    
    .dropdownOptions-menu.clicked {
      display: none;
      z-index: 99999;

    }
    
    .dropdownOptions-link {
      display: flex;
      height: 100%;
      width: 100%;
      text-decoration: none;
      color: #fff;
      padding: 12px;
      z-index: 99999;

    }

    .dropdownOptions-button {
      background: none;
      border: none;
      width: 80%;
      font-family: 'Montserrat';
      font-weight: 800;
      height: 100%;
      padding: 16px;
      color: #ededed;
      cursor: pointer;
      z-index: 99999;

    }

    .dropdownOptions-content {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      justify-content: space-between;
      color: #ededed;
      font-family: 'Montserrat';
      font-weight: 800;
      z-index: 99999;

    }




    .dropdownOptions-radio input[type="radio"] {
      background-color: rgb(105, 105, 105);
      border-radius: 10px;
      cursor: pointer;
      display: inline-block;
      height: 15px;
      margin-right: 5px;
      position: relative;
      width: 15px;
      -webkit-appearance: none;
  }
  .dropdownOptions-radio input[type="radio"]:after {
      border-radius: 25px;
      background-color: rgb(71, 71, 71);
      content: '';
      display: block;
      height: 9px;
      left: 3px;
      position: relative;
      top: 3px;
      width: 9px;
  }
  .dropdownOptions-radio input[type="radio"]:checked:after {
      background-color: #ff60b2;
  }
    
    @media screen and (max-width: 960px) {
  
      .dropdownOptions-menu {
        width: 220%;
        top: 60px;
        z-index: 200;
  
      }
  
      .fa-caret-down {
        display: none;
      }
    }