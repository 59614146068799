.vod-body {
    width: 100%;
    min-height: 1000px;
    background-color: #0A0B0D;
    display: flex;
}

.vod-primary {
    width: 80%;
    min-height: 1000px;
    overflow: hidden;
}

.vod-content {
    width: 99%;
    margin: 0 auto;
    margin-top: 30px;
    color: #ededed;
    margin-left: 10px;
    display: flex;
}

.vod-primary-title {
    width: 50%;
}

.vod-primary-title a {
    color: #ff60b2;
    cursor: pointer;
}

.vod-primary-title a:hover {
    border-bottom: 1px solid #ff60b2;
}


.vod-primary-title-secundary {
    width: 50%;
    min-height: 100px;
    display: flex;
    justify-content: right;
}


.vod-button-view-vod {
    background-color: #ff60b2;
    padding: 7px;
    border: none;
    font-family: 'Montserrat';
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
    margin-right: 20px;
}

.vod-button-view-vod:hover {
    background-color: #bd3b7e;
}

.vod-secondary {
    width: 17.7%;
    position: fixed;
    right: 0;
    border-left: 1px solid #ffffff1a;

}

.vod-secondary-title {
    background-color: #0c0c0c;
    color: #ededed;
    padding: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid #ffffff1a;
        border-left: 1px solid #ffffff1a;

}

.vod-secondary-chat {
    height: 830px;
    background-color: #0c0c0c;
    padding: 20px;
    border-left: 1px solid #ffffff1a;

}