.channel-clips-body {
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.channel-clips-container {
    width: 95%;
    min-height: 500px;
    padding: 0% 0% 0% 2%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    gap: 24px;
}

@media screen and (max-width: 758px) {
    .channel-clips-container {
        width: 95%;
        min-height: 500px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}