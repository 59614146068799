.RecoverPasswordContainer {
    width: 51%;
    height: max-content;
    padding: 30px;
    text-align: center;
    background: #18181b;
    position: absolute;
    top: 200px;
    left: 20%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
    display: flex;
    z-index: 9999;
}

.RecoverPassword {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.RecoverPasswordInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
}

.RecoverPasswordInputs input {
    background-color: rgb(65, 65, 65);
    border: none;
    padding: 7px;
    width: 100%;
    font-size: 12px;
    border-radius: 5px;
    color: #ededed;
}