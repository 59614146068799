.container_clip_data {
    display: flex;
    justify-content: center;
    z-index: 10000000000000000000000000;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background: #0f1214;
    padding: 12px;
    border-radius: 10px;
}

#container_clipSelectVideo {
    position: fixed;
    top: 15px;
    left: 0;
    width: 100%;
    height: 110%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.home-clips {
    width: 100%;
    overflow: hidden;
    margin-top: 24px;
}

.home-clips-card-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    color: #8a8a8ab9;
    margin-top: 40px;
}

.arrow-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.arrow-buttons i {
    background-color: #303030;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    color: darkgray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    color: #f36196;

}

.arrow-buttons i:hover {
    color: #f36196;
}

.fa-arrow-div {
    height: 200px;
    align-items: center;
    padding: 10px;
}

.home-clips-card-container-clips {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.home-clips-card-container-clips-children {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px
}

.loader-container {
    left: 51%;
    position: absolute;
    /* width: 400px; */
    /* height: 200px; */
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
}

.closedTargetClip {
    font-size: 42px;
    cursor: pointer;
    color: rgb(255, 255, 255);
    padding: 10px;
    display: flex;
    top: 10px;
    align-items: flex-start;
    height: 44px;
}

.loading-spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.customPlayer-container-selectClips {
    position: relative;
    z-index: 1000000;
    top: -60px;
}

.customPlayer-secundary-div-selectClips {
    display: flex;
    align-items: center;
    color: #fff;
    background: #191b1fb3;
}

.customPlayer-card-selectClips {
    margin: 10px;
}