.achievementcard-body {
    width: 100%;
    height: 125px;
    background-color: #151515;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.achievementcard-content {
    margin-left: 10px;
}

.achievementcard-progress {
    width: 100%;
    height: 20px;
    background-color: #1E1E1E;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
}

.achievementcard-progress-bar-fill {
    height: 20px;
    background: linear-gradient(90deg, rgba(243,97,150,1) 0%, rgba(254,97,153,1) 35%, rgba(250,136,177,1) 69%, rgba(255,33,113,1) 100%);
    border-radius: 5px;
}

.achievementcard-button {
    width: 105px;
    height: 35px;
    background: linear-gradient(90deg, rgba(243,97,150,1) 0%, rgba(254,97,153,1) 35%, rgba(250,136,177,1) 69%, rgba(255,33,113,1) 100%);
    font-weight: 600;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ededed;
    cursor: pointer;
}

.achievementcard-button:hover {
    background: linear-gradient(90deg, rgba(243,97,150,1) 0%, rgba(254,97,153,1) 35%, rgba(250,136,177,1) 69%, rgba(255,33,113,1) 100%);
    opacity: 0.8;
}

.achievementcard-button-empty {
    width: 105px;
    height: 35px;
    background-color: #303030;
    border: none;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ededed;
    cursor: pointer;
}