.clip-card-mobile {
    display: flex;
    flex-direction: column;
    background-color: #000;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
    position: relative;
    top: 248px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.clip-video-mobile {
    width: 100%;
    height: auto;
    background: #000;
}

.video-info-mobile {
    position: absolute;
    bottom: 0%;
    left: 2%;
    display: flex;
    align-items: center;
    color: white;
}

.text-info {
    margin-bottom: 10px;
}

.text-info h3 {
    font-size: 1rem;
    font-weight: bold;
}

.text-info h6 {
    font-size: 0.9rem;
    color: #aaa;
}



.video-actions-mobile {
    position: absolute;
    right: 2%;
    bottom: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.action-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff !important;
}

.action-icon svg {
    margin-bottom: 5px;
}

.profile-pic {
    border: 2px solid #fff;
}

.clipmain-comments-container-mobile {
    z-index: 8888;
    position: fixed;
    left: 17%;
    position: fixed;
    top: 10px;
    overflow-y: auto;
}

.clipsmain-top-buttons-mobile {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: space-between;
    left: 16px;
    /* margin: 0 auto; */
    position: relative;
    top: -7px;
    width: 49px;
    height: 43px;
}

.clipcard-muted-mobile {
    position: absolute;
    top: 38%;
    left: -220px;
}