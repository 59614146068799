.clipcard-follow {
    height: 19px;
    width: 19px;
    background-color: red;
    border-radius: 50px;
    display: flex;
    align-items: center;
    font-size: 17px;
    justify-content: center;
    color: white;
    font-family: Poppins;
    position: absolute;
    left: 15px;
    margin-top: -15px;
}

a {
    text-decoration: none;
}

.clipcard-muted {
    position: absolute;
    top: 50%;
    left: 50%;
}

.CardInfoUserClip {
    align-items: center;
    display: flex;
    font-weight: bolder;
    gap: 10px;
    justify-content: space-between;
    left: 9px;
    position: absolute;
    top: 40px;
    /* width: 72%; */
    z-index: 100000000000;
}

@media screen and (max-width: 768px) {
    .clipcard-muted {
        position: absolute;
        top: 20%;
    }

    .CardInfoUserClip {
        display: flex;
        align-items: center;
        gap: 10px;
        /* width: 100%; */
        justify-content: space-between;
        position: absolute;
        top: 38px;
        left: 243px;
        z-index: 100000000000;
    }

}

.clipcard-progress-body {
    width: 100%;
    margin: 0 auto;
    color: #ededed;
    margin-top: -19px;
    transition: 0.7s;
}

.clipcard-progress-body p {
    opacity: 0;
}

.clipsmain-video:hover .clipcard-progress-body p {
    opacity: 1;
}

.clipcard-icon-like {
    color: #ededed;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
}

.clipcard-icon-like:hover {
    color: #f36196;
}

.clipcard-icon-comment {
    color: #ededed;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
}

.clipcard-icon-comment:hover {
    color: rgb(46, 220, 255);
}

.clipcard-icon-share {
    color: #ededed;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
}

.clipcard-icon-share:hover {
    color: rgb(248, 255, 46);
}

.clipmain-comments-container {
    z-index: 8888;
    position: relative;
    left: -30px;
    animation: 1s moveToRight ease-in-out;
    overflow-y: hidden;
}

/* .clipsmain-right-buttons {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    left: 30px;
    width: 50px
} */

@keyframes moveToLeft {
    0% {
        R: 0px;
        opacity: 0;
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        left: -30px;
    }
}

@keyframes moveToRight {
    0% {
        left: -30px;
    }

    100% {
        left: 0px;
    }
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 1;
        transform: scale(1.0);
    }
}

.video-placeholder {
    width: 100%;
    height: 100%;
    background-color: black;
}

/* ClipCard.css */
.ambilight-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* Ensure canvas is behind the video */
}

.clipsmain-video {
    position: relative;
    width: 100%;
    height: auto;
}

.video-hover {
    opacity: 0.8;
    /* Reduce opacity for hover effect */
}

#ambilight {
    filter: blur(80px) opacity(0.5) saturate(300%);
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: scale(1.1) translateZ(0);
    width: 100%;
    z-index: -1;
    height: 100%;
}