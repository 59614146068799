#container_clipGet {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0rem 0rem 0rem;
}

#container_clip {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.reactPlayer {
    width: 70%;
    max-width: 800px;
    height: 400px;
    border-radius: 8px;
    overflow: hidden;
}

.container_clip_data {
    display: flex;
    justify-content: center;
    z-index: 10000;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background: #0f1214;
    padding: 12px;
    border-radius: 10px;

}

.container_clip_data a {
    padding: 0px;
}

.container_clip_dataGet {
    display: flex;
    justify-content: center;
    z-index: 10000;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background: #080808;
    position: relative;
    width: 91%;

}

.container_data {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    background: #232327;
    z-index: 10000;

}

.container_data_user {
    width: 40%;
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    align-content: space-around;
    justify-content: flex-start;
}

.container_data_user img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 10px;
}

.container_data_user div {
    display: flex;
    flex-direction: column;
}

.container_data_user h1 {
    font-size: 20px;
    margin: 0;
    color: #fff;
}

.container_data_user p {
    font-size: 14px;
    color: #ccc;
}

.container_data_clip {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 62px;
    justify-content: space-between;
    padding: 10px 0px;
    background: #0f1214
}

.title-clip-card-select {
    font-weight: bolder;
    font-size: 16px;
    font-family: inter;
    color: #fff;
    padding-right: 3px;
}


.containerCreator-title {
    display: flex;
    flex-direction: column;
}

.container_data_clip span {
    font-size: 16px;
    color: #fff;
}

.container_data_clip span:first-child {
    color: #ccc;
    font-weight: bold;
}

.container_data_clip span:last-child {
    font-weight: bold;
}