.video-video-container {
  position: relative;
  width: 100vw;
  height: 95vh;
  overflow: hidden;
  background-color: black;
}

.video-wrapper {
  width: 100%;
  height: 100%;
}

.video-player {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: width .5s ease-in-out ;
}

.video-info {
  position: absolute;
  bottom: 15%;
  left: 2%;
  display: flex;
  align-items: center;
  color: white;
}

.text-info {
  margin-left: 10px;
}

.video-actions {
  position: absolute;
  right: 2%;
  bottom: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.action-icon {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clip-wrapper {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.clip-wrapper.active {
  opacity: 1;
  transform: translateY(0);
}

.clip-wrapper.up.animating {
  transform: translateY(-100px);
}

.clip-wrapper.down.animating {
  transform: translateY(100px);
}