/* Para el contenedor principal que ocupa la pantalla completa */
.chat-settings-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: #00000054;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

/* El panel de configuraciones ocupará el 50% del ancho y 100% del alto */
.chat-settings {
    width: 50%;
    height: 71%;
    background-color: rgb(0, 0, 0);
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow-y: auto;
    box-shadow: 0px 2px 1px -1px rgb(159 71 140), 0px 1px 1px 0px rgb(190 90 191), 0px 1px 3px 0px rgb(152 69 140) !important;
    /* Habilitar scroll si el contenido es más largo que la pantalla */
}

.chat-settings>h2 {
    font-weight: bolder;
    color: aliceblue;
}

.user-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.current-status {
    margin-bottom: 20px;
}

.settings-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.crp2z {
    display: flex;
    color: aliceblue;
    margin-top: 20px;
    justify-content: space-between;
}

.settings-options-mover {
    color: white;
    padding: 10px;
}

.settings-option {
    padding: 10px;
    border: none;
    background-color: #000000;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    color: white;
}

.settings-option:hover {
    background-color: #7b3856;
}

.settings-option.danger {
    color: white;
}

.settings-option.danger:hover {
    background-color: #7b3856;
}