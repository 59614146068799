


.custom-categories {
    width: 95%;
    margin: 0 auto;
    min-height: 300px;
    margin-top: 20px;
    z-index: 10000;
}

.custom-categories-card-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 150px;
    flex-direction: row;
    justify-content: center;

}

.custom-categories-card {
    margin-top: 8px !important;
    /* max-width: 150px; */
    min-width: 150px;
    margin: 10px 0px;
    border-radius: 5px;
    cursor: pointer;
    /* box-shadow: black 0px 2px 7px 0.5px; */

}

.custom-categories-card img {
    transition: 0.9s all;
    object-fit: cover;
    border-radius: 10px;
}

.custom-categories-card-contain {
    width: 100%;
    transition: 0.2s all;
}

.custom-categories-card-contain:hover {
    color: #ff693d;
    font-weight: bold;
    transform: translate3d(0px, -9px, 0px);
    cursor: pointer;
}

.custom-categories-p-1 {
    width: 100%;
    color: #ededed;
}

.custom-categories-link-hover {
    color: white;
}

.custom-categories-link-hover:hover {
    color: #f36196;
}

.custom-categories-p-1:hover {
    color: #f36196;
}

.custom-categories-p-2 {
    display: flex;
    color: darkgray;
    align-content: center;
    align-items: center;
    flex-direction: row;
}

.custom-categories-p-2 p:hover {
    /* color: #f36196; */
}

.text-remarcado:hover {
    border-bottom: 1px solid #f36196;
    cursor: pointer;
}

.custom-categories-card-tag-container {
    display: flex;
    align-items: center;
}

.custom-card-tag {
    background-color: #30353f;
    border-radius: 7px;
    color: rgb(204, 204, 204);
    padding: 5px;
    margin-top: 5px;
    letter-spacing: 0.7px;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.4px;
    cursor: pointer;
    margin-right: 5px;
    font-family: ProximaNova, Arial, Tahoma, PingFangSC, sans-serif;
}

.custom-card-tag:hover {
    background-color: #1c1d1f;
}

.custom-carousel {
    background-color: red;
    height: 300px;
}

.styles-module_carousel-arrow__26sRw {
    display: none;
}

@media screen and (max-width: 768px) {
    .custom-categories {
        width: 90%;
    }

    .custom-categories-card {
        display: flex;
        align-items: center;
        padding: 5px 0px;
        flex-direction: column;
    }

    .user_data_contain {
        display: flex;
        margin-left: 12px !important;
        width: 100% !important;
        font-size: 18px !important;
    }
}



.custom-categories-card-contain-pinkker {
    position: absolute !important;
    margin: 0rem 1.9rem;
    width: 104px;
    height: 17px;
    text-align: center;
    align-items: center;
    border-radius: 3px;
    font-size: 7px;
    font-weight: bold;
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-content: center;

}

.custom-categories-card-contain-pinkker>span {
    color: #ffffff;
    letter-spacing: 1px;
}

@media screen and (max-width: 768px) {

    .custom-categories {
        width: 90%;
    }
}

.user_data_contain {
    display: flex;
    margin: 5px 0px;
    overflow: hidden;
}

.img-categorie-card {
    width: 100%;
    height: 199px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
}

.custom-categories-p-1-name {
    font-size: 15px;
    font-family: Inter, sans-serif;
    cursor: pointer;
    font-weight: 300;
    letter-spacing: 0.6px;
    margin-top: 3px;
}

@keyframes shadowPulseAnimation {
    0% {
        box-shadow: 0 0 00e70166;
    }

    30% {
        box-shadow: 0 0 2px 2px #00e70166
    }

    70% {
        box-shadow: 0 0 2px 2px #00e70166;
    }
}

#pulsatingDot {
    width: 7px;
    height: 7px;
    background-color: #1fff20;
    border-radius: 50%;
    margin-top: 2px;
    margin-left: 5px;
    margin-right: 7px;
    animation: shadowPulseAnimation 1s infinite ease-out;
}

.custom-categories-p-2-spectators {
    text-align: center;
    color: #a4aec6;
    font-size: 13px;
    font-family: Lato, sans-serif;

}

.custom-categories-p-2-spectators>span {
    padding: 5px;
    font-weight: 600;
    color: #ffff;
    font-family: Lato, sans-serif;

}