.clipsmain-body {
    display: flex;
    position: relative;
    top: 0%;
    height: 100%;
    width: 102%;
    justify-content: center;
    padding: 0px;
    align-items: center;
    max-width: 100%;
    scroll-behavior: smooth;
    transition: 5s all;


}

.clipmain-card-main {
    display: flex;
    justify-content: center;
    transition: 5s all;
    /* background: #080808; */

}

.clipsmain-container {
    height: max-content;
    position: relative;
    display: flex;
    align-items: center;
    transition: 5s all;
    scroll-behavior: smooth;
    justify-content: center;
    width: 900px;
}

.clipsmain-video {
    display: flex;
    align-items: center;
    scroll-behavior: smooth;
    transition: 5s all;
    flex-direction: column;
    /* width: 500px; */
    height: 570px;

}

@media screen and (max-width: 768px) {
    .clipsmain-video {
        display: flex;
        align-items: center;
        scroll-behavior: smooth;
        transition: 5s all;
        flex-direction: column;
        width: 530px;
        height: 570px;
    }

    .clips-container {
        position: relative;
        /* top: 40px;  */
    }
}

.clipsmain-video-Loading {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: 5s all;
}

.clipsmain-video video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: 5s all;
}

.video-loading {
    background-color: black;
}

.clipsmain-top-buttons {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: space-between;
    left: 37%;
    margin: 0 auto;
    position: relative;
    top: 15%;
    top: 47px;
}

.clipsmain-bottom-buttons {
    color: white;
    width: 100%;
    margin: 0 auto;
}

.clipsmain-right-buttons {
    width: 120px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
}

@media screen and (max-width: 768px) {
    .clipsmain-right-buttons {
        display: none;
        width: 120px;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
    }

}

.clipsmain-body {
    max-width: 100%;
    scroll-behavior: smooth;
}

.clip-wrapper {
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    display: none;
}

.clip-wrapper.active {

    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    display: block;

}

.clips-container.up .clip-wrapper.active {
    transform: translateY(100%);
    opacity: 0;
}

.clips-container.down .clip-wrapper.active {
    transform: translateY(-100%);
    opacity: 0;
}

.clips-container {
    top: 40px;
    position: relative;
}

.clips-container.up .clip-wrapper {
    transform: translateY(100%);
}

.clips-container.down .clip-wrapper {
    transform: translateY(-100%);
}

.clipcard-send-comment {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: #151515;
    justify-content: space-between;
    padding: 8px 16px;
    border-radius: 8px;
}

.clipcard-send-comment>div {
    display: flex;
    align-items: center;
}

.clipcard-send-comment>.-input {
    flex-grow: 1;
    margin: 0 8px;
}

.clipcard-send-comment textarea {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    background-color: #1e1e1e;
    color: white;
    border: none;
    border-radius: 4px;
    resize: none;
}

.clipcard-send-comment button {
    background-color: #f36197;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
}

.clipcard-send-comment button:hover {
    background-color: #e8508b;
}

.video-placeholder-loading>img {
    width: 100%;
}