.comments-body {
    width: 95%;
    min-height: 300px;
    margin: 0 auto;
    margin-top: 25px;
}

.comments-title {
    width: 100%;
    color: #ededed;
    display: flex;
    align-items: center;
}

.comments-button-sort {
    width: 10%;
    height: 35px;
    margin-left: 20px;
    color: #ededed;
    font-weight: 600;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.comments-user-comment {
    width: 100%;
    display: flex;
    height: 100px;
    margin-top: 10px;
}

.comments-input {
    background-color: transparent;
    border: none;
    font-size: 14px;
    border-radius: 0px;
    color: #ededed;
    width: 100%;
    padding: 5px;
    border-bottom: 1px solid #ededed;
    outline: none;
}

.comments-input:focus{
    border-bottom: 1px solid #ff60b2;
}
  


.comments-button-cancel {
    padding: 10px;
    margin: 5px;
    background-color: transparent;
    border: none;
    color: #ededed;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}

.comments-button-comment {
    padding: 10px;
    margin: 5px;
    background-color: #ff60b2;
    border: none;
    color: #ededed;
    border-radius: 3px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
}