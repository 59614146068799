.MuiDialog-root {
    z-index: 1300 !important;
}


.communities-container {
    background: #080808;
    border-radius: 8px;
    padding: 20px;
    max-width: 100%;
    /* margin: 20px auto; */
    /* box-shadow: 0px 2px 1px -1px rgb(159 71 140), 0px 1px 1px 0px rgb(190 90 191), 0px 1px 3px 0px rgb(152 69 140) !important; */
}

@media screen and (max-width: 768px) {
    .communities-container {
        background: #080808;
        border-radius: 8px;
       padding: 0;
        max-width: 100%;
        /* margin: 20px auto; */
        /* box-shadow: 0px 2px 1px -1px rgb(159 71 140), 0px 1px 1px 0px rgb(190 90 191), 0px 1px 3px 0px rgb(152 69 140) !important; */
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    color: #ffffff !important;
}

.create-button {
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    padding: 10px 20px;
}

.create-button:hover {
    background-color: #0056b3;
}

.community-title {
    color: #ffffff;
}

.inputsStyles {
    background-color: #30182d6b !important;
    border-radius: 10px !important;
}

.custom-textfield .MuiFormLabel-root {
    color: #ff69c4 !important;
}

.custom-textfield .MuiOutlinedInput-root {
    border-color: #ff69c4 !important;
}

.custom-textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ff53da !important;
}

.custom-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ff69c4 !important;
}

.custom-textfield .MuiInputLabel-root {
    color: #ff53da !important;
}

.custom-textfield .MuiInputBase-input {
    color: #ff53da !important;
}


.title {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    color: #ffffff !important;
    text-align: center;
}

/* lista */
.communities-list {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px;
    scrollbar-width: thin;
    scrollbar-color: #ff69c4 transparent;
}

.communities-list::-webkit-scrollbar {
    height: 8px;
}

.communities-list::-webkit-scrollbar-thumb {
    background-color: #ff69c4;
    border-radius: 10px;
}

.community-card {
    width: 186px;
    height: 114px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    border: 1px solid #ff69c4;
    flex-shrink: 0;
}

.community-card:hover {
    transform: scale(1.05);
}

.banner-container {
    width: 100%;
    height: 64%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.community-banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.community-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    overflow: hidden;
}
.Community-Info{
    background-color: #202329;
    padding: 5px;
    border-radius: 5px;
}
.community-name {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
}

.community-title {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    max-width: 100%;
    color: #333;
}

.community-title::after {
    content: attr(data-title);
    position: absolute;
    left: 0;
    opacity: 0.3;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
}

.community-members {
    position: relative;
    font-size: 14px;
    color: #666;
    cursor: pointer;
}

.community-members:hover::after {
    content: attr(title);
    position: absolute;
    top: -30px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}

/* lista */

.CommunityInfo {
    background-color: rgb(8 8 8);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
}



.communities-search {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.muro-post-container-CommunityName {
    color: #ffffff;
    font-weight: bolder;
    padding: 0px 5px;
}

.communitiesRedirect-post {
    padding: 5px;
}

.modal-dialog-info-community {
    z-index: 100000 !important;

}

.modal-dialog-info-community .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background: #080808 !important;
    border-radius: 12px !important;
    box-shadow: 0 2px 1px -1px #9f478c, 0 1px 1px 0 #be5abf, 0 1px 3px 0 #98458c !important;
    color: #ffffff;
    font-weight: inherit !important;
    padding: 10px;
}

.modal-dialog-info-community .css-qfso29-MuiTypography-root-MuiDialogContentText-root {
    color: #ffffff;
    font-weight: inherit !important;
    margin: 0px 23px;
}

.modal-dialog-info-community .MuiButton-root {
    background-color: #100b0d !important;
    color: #fff !important;
    font-weight: 700 !important;
    margin-top: 15px !important;
    font-size: 13px;

    &:hover {
        background-color: #f3619645 !important;
    }
}

.text-Community {
    font-size: 39px !important;
    background-color: rgba(0, 0, 0, 0.00);
    border: 0 solid black !important;
    box-sizing: border-box !important;
    display: inline !important;
    font: inherit !important;
    list-style: none !important;
    margin: 0px !important;
    padding: 0px !important;
    position: relative !important;
    text-align: inherit !important;
    text-decoration: none !important;
    word-wrap: break-word !important;
    color: #b0b0b0 !important;
    font-weight: bolder !important;
}

.title {
    font-size: 30px !important;
}

.community-categorie {
    border: 1px solid rgb(71 31 46);
    padding: 5px 15px;
    border-radius: 20px;
    color: #a84a6d;
    font-size: 14px;
    text-align: center;
}

.reactEasyCrop_Container {
    z-index: 100 !important;
}