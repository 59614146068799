@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdownemotes-menu {
  position: absolute;
  margin-top: -394px;
  left: 5%;
  width: 81%;
  height: 293px;
  display: flex;
  background: #000000;
  border: 1px solid #15151563;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
  border-bottom: 1px solid #ffffff1a;
  overflow: hidden;
  box-shadow: 1px -1px 12px #4f2a39;
}

.dropdownemotes-container {
  overflow: scroll;
  overflow-x: hidden;
}

.dropdownemotes-muro-menu {
  position: absolute;
  z-index: 1001;
  width: 342px;
  height: 303px;
  display: flex;
  background: #0a0a0afc;
  border: 1px solid #15151563;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
  border-bottom: 1px solid #ffffff1a;
}

.dropdownemotes-menu li {
  cursor: pointer;
  display: flex;
  text-align: center;
  font-size: 13px;
  border-radius: 5px;
}

.dropdownemotes-menu i {
  transition: 0.3s all;

}

.dropdownemotes-menu li:hover .dropdownemotes-content i {
  transform: translateY(-3px);
}

.dropdownemotes-menu li:hover {
  background: #2e2e2e;
}

.dropdownemotes-menu.clicked {
  display: none;
}

.dropdownemotes-link {
  display: flex;
  justify-content: flex-end;
  font-family: "Poppins";
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 5px;
  height: 4px;
}

.dropdownemotes-button {
  background: none;
  border: none;
  width: 80%;
  font-family: 'Montserrat';
  font-weight: 800;
  height: 100%;
  padding: 16px;
  color: #ededed;
  cursor: pointer;

}

.dropdownemotes-content {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  color: #ededed;
  font-family: 'Montserrat';
  font-weight: 800;
}

.dropdownemotes-input {
  display: flex;
  background-color: #0A0B0D;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
}

.dropdownemotes-input input {
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 5px;
  color: #ededed;
  font-family: 'Poppins';
  font-size: 12px;
}

.dropdownemotes-primary {
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.hr-dropdownemotes-primary {
  border: 1px solid rgb(44 25 33 / 33%);
  width: 100%;
  margin-bottom: 10px;
}

.dropdown-secondary {
  width: 15%;
  height: 250px;
  margin-left: 5%;
}

.dropdown-secondary-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25px;
  cursor: pointer;
  margin-top: 10px;
}

.dropdown-secondary-card:hover {
  background-color: #303030;
}

.dropdownemotes-emote {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2px;
  border-radius: 3px;
  margin-top: 7px;
  margin-right: 4px;
}

.dropdownemotes-emote:hover {
  background-color: #303030;
}

@media screen and (max-width: 960px) {

  .dropdownemotes-menu {
    /* position: relative; */
    width: 409px;
    /* top: 390px; */
    left: 88px;
    z-index: 1000000000000000;
  }

  .fa-caret-down {
    display: none;
  }
}

.DropDownButton {
  display: flex;
  width: 31px;
  height: 31px;
  align-items: center;
  margin: 0px;
  justify-content: center;
  background: none;
}

.DropDownButton img {
  width: 20px;
}