.userthemes-body {
    width: 50%;
    margin-top: 50px;
}

.userthemes-container {
    width: 100%;
    background-color: rgb(22, 22, 22);
    border-radius: 5px;
    min-height: 100px;
    display: flex;
    align-items: center;
}

.userthemes-content {
    width: 32%;
    margin: 1% auto;
    text-align: center;
}

.userthemes-content button{
   width: 50%;
   padding: 15px;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   font-family: 'Poppins';
}


.userthemes-active button{
    border: 1px solid #ff60b2;
}