.citetweet-popup-body {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;

}

.citetweet-popup-container {
    width: 35%;
    min-height: 400px;
    overflow: scroll;
    margin: auto;
    padding: 30px;
    text-align: center;
    background: #18181b;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
}

#citetweet-textarea {
    background-color: #3a3b3c;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0px 10px;
    border-radius: 5px;
    width: 90%;
}

.citetweet-popup-primary {
    display: flex;
}

.citetweet-popup-avatar {
    width: 70px;
}

.citetweet-popup-tweet {
    width: 95%;
    min-height: 75px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ffffff1a;
    text-align: left;
    color: white;
}