.explore-body {
 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc(1400px + 6vw);
  margin: 0 auto;
  overflow: hidden;
}

.explore-container {
    width: 100%;
    position: relative;
    top: 10px;
}


.explore-title {
    width: 100%;
    margin: 0 auto;
    font-family: 'Poppins';
    font-weight: 700;
    color: #ededed;
}


.explore-banners {
    width: 100%;
    margin: 0 auto;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.explore-banner {
    min-width: 301px;
    max-width: 301px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #ededed;
    cursor: pointer;
    margin-right: 5px;
}

.explore-card-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 758px) {

    .explore-body {
        display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  overflow: hidden;
    }
}

.filter-container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.filter-container button {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
}

.filter-container .active {
  background-color: #007bff;
  color: white;
}

video {
  max-height: 100%;
  width: auto;
}

button {
  background-color: #f36196;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.filter-container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.filter-container button {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
}

.filter-container .active {
  background-color: #007bff;
  color: white;
}

video {
  max-height: 100%;
  width: auto;
}

.video-actions {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-actions button {
  background: none;
  border: none;
  font-size: 24px;
  margin: 10px 0;
  cursor: pointer;
  color: white;
}