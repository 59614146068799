.featured-body {
    width: 100%;
    display: flex;
    align-items: flex-start;

}


.featured-video video{
    width: 100%;
    height: 300px;
    position: relative;
    top: -15px;
}

.featured-info {
    width: 35%;
    margin-left: 10px;
    color: #ededed;
    margin-top: 5px;
}
