.navbar-search-dark {
  
  position: relative;
  text-align: center;
  background-color: #080808;
  
  border: 1px solid #2a2e38;
  border-radius: 6px;
  overflow: hidden;
  transition: all 1s;
  display: flex;
  /* Oculta el contenido que se desborda */
}

.navbar-search-dark:hover {
  background-color: #2a2e38;
}

.input-searchbar::placeholder{
  font-size: 0.875rem;
}


.navbar-search-margin {
  /* margin-bottom: 280px; */
}

.navbar-search-dark input {
  background-color: transparent;
  width: 100%;
  /* Cambiado a 100% para llenar el contenedor */
  /* padding: 7.5px; */
  height: 35px;
  font-family: 'Montserrat';
  outline: none;
  color: rgb(89 89 89);

  border: none;
  overflow: hidden;
  /* Oculta el contenido que se desborda */
}

.navbar-search-dark input::placeholder {
  color: rgb(89 89 89);
}

.navbar-search-i {
  width: 35px;
  height: 16px;
  padding: 5px;
  margin-left: 2px;
  outline: none;
  border: none;
  color: rgb(255, 255, 255);
  border-radius: 50px;
}

.navbar-search-i {
  width: 35px;
  height: 16px;
  padding: 5px;
  margin-left: 2px;
  outline: none;
  border: none;
  color: rgb(255, 255, 255);
  border-radius: 50px;

}

.navbar-search-i:hover {
  cursor: pointer;
}

input::placeholder {
  color: rgb(219, 219, 219);
}

.navbar-search-dark input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  filter: invert(1);

}

.navbar-search-dark input:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
}

@media screen and (max-width: 768px) {
  .navbar-search-dark {
    width: 30% !important;
    left: 0px !important;
   
  }

  .navbar-search-margin {
    margin: 0 auto !important;
    left: 0px !important;
    width: 92% !important;
    height: 50px !important;
  }
}