.dashboard-body{
    width: 100%;
    min-height: 1000px;
    background-color:#0a0b0d;

}

.dashboard-container {
    width: 60%;
    margin: 0 auto;
    position: relative;
    color: #ededed;
    z-index: 1000;
}

.dashboard-logro-card-container {
    width: 100%;
    height: 250px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard-logro-card {
    height: 200px;
    border-radius: 5px;
    background-color: #151515;
    margin-right: 10px;
    cursor: pointer;
}

.dashboard-logro-card:hover {
    background-color: #303030;
}





















/* BACKGROUND */


.background-container{
	position: fixed;
	top: 0;
	left:0;
	bottom: 0;
	right: 0;
  z-index: 1;
}

.stars {
 background: black url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
 position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: block;
  	z-index: 0;
}

.twinkling{
	width:10000px;
	height: 100%;
	background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png") repeat;
	background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    
    -moz-animation:move-background 70s linear infinite;
  -ms-animation:move-background 70s linear infinite;
  -o-animation:move-background 70s linear infinite;
  -webkit-animation:move-background 70s linear infinite;
  animation:move-background 70s linear infinite;
	
}

.clouds{
	width:10000px;
	height: 100%;
	background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/clouds_repeat.png") repeat;
	background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;

   -moz-animation:move-background 150s linear infinite;
  -ms-animation:move-background 150s linear infinite;
  -o-animation:move-background 150s linear infinite;
  -webkit-animation:move-background 150s linear infinite;
  animation:move-background 150s linear infinite;
}
