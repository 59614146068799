.home-recommended {
    width: 100%;

}

.home-recommended-card-container {
    display: flex;
    flex-wrap: nowrap;
    color: #8a8a8ab9;
    height: 100% !important;
}

.home-recommended-card-container-streams {
    display: flex;
    flex-direction: row;

    overflow: hidden;
}



.manager-recommended {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.manager-recommended>h2 {
    font-weight: bolder;
    font-family: inter;
    font-size: 20px;
    color: #fff;
}

.manager-recommended-actions {
    display: flex;
    gap: 10px;
}

.manager-recommended-actions-ver-todos {
    display: flex !important;
    text-align: center;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
    background: #2a2e38;
    align-content: center;
    align-items: center;
    justify-content: center;
    color: #fff;

    font-family: "inter";
}

.manager-recommended-actions-ver-todos:hover {
    background: #343843;
}

.manager-recommended-actions-ver-todos>a {
    color: inherit;
    text-decoration: none;
}

.manager-recommended-actions-arrow {
    display: flex;
    align-items: center;
    gap: 0px;
    background: #2a2e38;
    border-radius: 30px;
    overflow: hidden;
}

.manager-recommended-actions-arrow > div {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    transition: background 0.3s;
}

.manager-recommended-actions-arrow .custom-prev {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.manager-recommended-actions-arrow .custom-next {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.manager-recommended-actions-arrow > div:hover {
    background: #343843;
}

.manager-recommended-actions-arrow > div.disabled {
    opacity: 0.5;
    pointer-events: none;
}

@media screen and (max-width: 768px) {

    .home-recommended {
        width: 100%;
        /* padding-bottom: 15% !important; */
    }

    .home-recommended-card-container-streams {
        width: 100% !important;

    }
}