/* PurchaseBuyAds.css */
.header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.purchase-ads {
    background: #080808;
    /* border: 1px solid #080808; */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 1px -1px rgb(159 71 140), 0px 1px 1px 0px rgb(190 90 191), 0px 1px 3px 0px rgb(152 69 140) !important;
    max-width: 800px;
    margin: 20px auto;
}

.css-4ljt47-MenuList {
    background: #190f17;
}


.purchase-ads .title {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    color: #ffffff !important;
}

.inputsStyles {
    background-color: #30182d6b !important;
    border-radius: 10px !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    border: none
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    color: #ff53da !important;

}

.custom-textfield .MuiFormLabel-root {
    color: #ff69c4 !important;
    /* Cambia el color del label */
}

.custom-textfield .Mui-focused .MuiFormLabel-root {
    color: #ff53da !important;
    border-color: #ff53da;
    /* Cambia el color cuando el input está enfocado */
}

.custom-textfield .MuiOutlinedInput-root {
    border-color: #ff69c4 !important;
    /* Color del borde por defecto */
}

.custom-textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ff53da !important;
    /* Color del borde cuando el mouse está encima */
}

.custom-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ff69c4 !important;
    /* Color del borde cuando está enfocado */
}

.custom-textfield .MuiInputLabel-root {
    color: #ff53da !important;
    /* Color del label */
}


.purchase-ads .create-ad-form {
    margin-bottom: 20px;
}

.purchase-ads .form-group {
    margin-bottom: 15px;
}

.purchase-ads .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #e0e0e0;
    /* Color claro para los labels */
}

.ReferenceLink {
    color: #e0e0e0;
}

.purchase-ads .form-input,
.purchase-ads .form-select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    /* Color del texto dentro de los inputs y selects */
    background-color: #212725;
    /* Fondo oscuro para inputs y selects */
}

.purchase-ads .form-input[type="number"] {
    -moz-appearance: textfield;
}

.purchase-ads .form-input[type="number"]::-webkit-inner-spin-button,
.purchase-ads .form-input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.purchase-ads .ad-info {
    background-color: #0000008a;
    /* Borde gris claro */
    border-radius: 8px;
    padding: 15px;
}

.purchase-ads .info-text {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 10px;
}

.purchase-ads .info-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.purchase-ads .info-list li {
    margin-bottom: 5px;
}

.purchase-ads .info-list strong {
    color: #ffffff;
}

.purchase-ads .create-btn {
    background-color: #007bff;
    /* Color de botón principal */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.purchase-ads .create-btn:hover {
    background-color: #0056b3;
}