.adminpagos-body {
    width: 100%;
    min-height: 1000px;
    background-color: #0A0B0D;
}

.adminpagos-container {
    width: 60%;
    margin: 0 auto;
}

.adminpagos-balance {
    width: 100%;
    height: 155px;
    background-color: #151515;
    border-radius: 5px;
    padding: 25px;
}

.adminpagos-balance-buttons {
    margin-top: 20px;
}

.adminpagos-balance-button {
    width: 125px;
    height: 35px;
    background-color: #ff60b2;
    border: none;
    border-radius: 5px;
    color: #ededed;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.adminpagos-balance-button:hover {
    background-color: #bd3b7e;
}

.adminpagos-history {
    width: 100%;
    padding: 25px;
    height: 550px;
    background-color: #151515;
    margin-top: 20px;
    border-radius: 5px;
}

.adminpagos-history-card{
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #ededed;
    display: flex;
    align-items: center;
    background-color: #303030;
    border-radius: 3px;
}

.adminpagos-history-card-name {
    width: 20%;
}

.adminpagos-history-card-destino {
    width: 7%;
}

.adminpagos-history-card-amount {
    width: 20%;
}

.adminpagos-history-card-status {
    width: 10%;
}


.adminpagos-history-card-date {
    width: 20%;
}