.channel-chat {
    position: relative;
    /* Este es el contexto para el sticky */
    top: 0px;
    z-index: 8888;

}

.ChatStreaming {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0px;
    /* Ajusta este valor según sea necesario */
    z-index: 15000;
    background: #080808;
    height: calc(100vh - 74px);
    /* Ajuste de altura */
}

.Conversation {
    display: flex;
    width: 100%;
    flex: 1;
    /* Esto asegura que ocupe todo el espacio vertical disponible */
    align-items: flex-start;
    flex-direction: column;
    max-height: unset;
    /* Elimina la restricción de altura fija */
    overflow-y: auto;
    background: #080808;
}

.Host {
    background: rgb(243 97 150);
    padding: 10px;
    align-items: center;
}

.ChatStreaming-message-main {
    display: flex;
}

.PinkkerPrime {
    background: url(https://res.cloudinary.com/pinkker/image/upload/v1677784906/naming/colaborador_qwwmv6.gif) -10px no-repeat;
    text-shadow: 0px 0px 8px;
    font-weight: 600;
}

.Message {
    width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding: 2px 5px;
    margin: 5px 7px;
    border-radius: 7px;
    position: relative;
}

.HostChat {
    display: flex;
    flex-direction: row;
    max-width: 96%;
    max-height: 100%;
    overflow-y: auto;
    align-items: center;
    font-size: 14px;
}

.contentHostInfo-spans {
    font-weight: bolder;
}

.contentHostInfo {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow-wrap: anywhere;
    word-break: break-all;
    font-weight: 400;
}

.MessagesChat {
    display: flex;
    flex-direction: row;
    max-width: 88%;
    max-height: 100%;
    overflow-y: auto;
    align-items: center;
    font-size: 14px;
}


.Message {
    display: flex;
    align-items: stretch;
    width: 93%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding: 2px 5px;
    margin: 5px 7px;
    border-radius: 7px;
    position: relative;
    flex-direction: column;
}

.Message:hover {
    background-color: #f3619769;
}

.hover-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
}

.Message:hover .hover-button {
    opacity: 1;
}

.hover-btn {
    background-color: #232628;
    padding: 0px 0px;
    position: relative;
    left: 100px;
    height: 20px;
    width: 24px;
    border: 1px solid #ffffff2b;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-info-message-2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow-wrap: anywhere;
    word-break: break-all;
    font-weight: 400;
}

.MessagesChat img {
    position: relative;
    top: 4px;
    width: 20px;
    height: auto;
}

.chat-donation-card-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.getDonationSubscriptionCard {
    height: max-content;
    width: 88%;
    z-index: 1000000000000000000;
}

.getDonationSubscriptionCardshowAllSubs {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 98px;
    width: 100%;
    justify-content: center;
    z-index: 100;
    background-color: #080808;

}

.badges {
    display: flex;
    height: 21px;
    /* width: 30px; */
    flex-direction: row;
    align-items: center;

    font-size: 12px;
}

.badges:hover {
    cursor: pointer;
    background-color: #f36396;
    border-radius: 5px;
}

.badges>img {
    margin: 0px 2px;
    width: 18px;
    height: 18px;
    border-radius: 2px;
}

.sas {
    display: flex;
    width: 22px;
    align-items: center;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: row;
}


#Chats_section {
    display: flex;
    background-color: black;
}

.Chats_section_ChatsTheUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Conversation {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1;
    max-height: 77%;
    overflow-y: auto;
    background: #080808;
}

.Conversation::-webkit-scrollbar {
    display: none;
}

.Conversation {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.Conversation:hover {
    scrollbar-color: transparent transparent;
}

.ChatApp {
    width: 200px;
    height: 200px;
}

.icono-hover:hover {
    background-color: #303030;
}

.ChatStreaming_form {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    border: 1px solid #2a2e38;
    border-radius: 5px;
    gap: 10px;
}

.ChatStreaming_form>span {
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 5px 0px 0px 5px;

    border-right: none;
    background: #080808;
    text-align: left;
    padding: 5px;
    font-weight: bold;
}

.ChatStreaming_form input {
    width: 95%;
    height: 37px;
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
    background: #080808;
    color: #fff;
    text-align: left;
    padding: 0px 0px 0px 15px;
    font-weight: bold;
}

.config-buttoncloseNavbarDropdownEmotes {
    align-items: center;
    background: #080808;
    border-left: none;
    border-radius: 0 5px 5px 0;
    color: #ededed;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 38px;
    padding: 5px;
    padding-right: 11px;
}

.config-buttoncloseNavbarDropdownEmotes:hover {
    background-color: #303030;
}

.divinput-chat {
    width: 95%;
    display: flex;
    font-size: 12px;
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
    height: 100%;
    border-left: none;
    border-right: none;
    background: #080808;
    color: #EFEFF1;
    text-align: left;
    padding: 0px 0px 0px 2px;
    font-weight: normal;
    outline: none;
    align-items: center;
}

.button-enviar {
    background-color: #ff50a6;
    border: 1px solid #ff50a6;
    display: flex;
    text-shadow: rgb(0, 0, 0) 0px 1px 0px;
    align-items: center;
    justify-content: center;
    padding: 5px 1rem;
    font-size: 14px;
    text-shadow: '0 1px 0 #000';
    height: 30px;
    border-radius: 0.375rem;
    color: #ededed;
    width: 100%;
    text-transform: capitalize;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.button-enviar:hover {
    background-color: #ff86e4;
}

.divinput-chat:empty:before {
    content: attr(placeholder);
    color: #aaa;
    font-size: 13px;
}

.divinput-chat:focus {
    /* border: none; */
}

.content-info-message-2-nameUser-span {
    font-weight: bolder;
}

.content-parse-message {
    color: #ffff;
    word-break: keep-all;
}

.ChatStreaming_form>button {
    height: 41px;
    background-color: #171a1f;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    border-radius: 0px 5px 5px 0px;
    border: #545658 1px solid;
    border-left: none;
    font-size: 16px;
    transition: background-color 0.3s;
}

@media screen and (max-width: 768px) {
    .ChatStreaming {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
        left: 0;
        height: 50dvh;
    }

    .channel-chat {
        height: 400px;
        overflow-y: auto;
    }

    .Conversation {
        display: flex;
        width: 100%;
        z-index: 1000;
        align-items: flex-start;
        flex-direction: column;
        flex: 1;
        /* max-height: 45%; */
        overflow-y: auto;
    }

    .ChatStreaming_form {
        display: flex;
        height: auto;
        width: 100%;
        justify-content: center;
        flex-direction: row;
    }

}

.info_chat_extra {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #080808;
}

.actions-chat-conteiner {
    width: 100%;
    background: #080808;
    /* box-shadow: 2px 1px 20px -13px lemonchiffon; */
    border-top: 1px solid #2a2e38;
    border-radius: 3px;
}

.actions-chat-conteiner-content {
    padding: 11px 15px;
}

.actions-chat {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}

.marquee {
    animation: marquee 20s linear infinite;
}

.marquee:hover {
    animation-play-state: paused;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 768px) {



    /* .getDonationSubscriptionCard {
        width: 100%;
    } */
}

.ShowGetUserTheChat {
    background-color: #313538;
    color: #ffff;
    position: absolute;
    width: 340px;
    z-index: 10000;
    font-family: inter;
    font-size: 0.874rem;
    line-height: 1.25rem;
    font-weight: 700;
    right: 100%;
    bottom: 10px;
    padding: 5px;
}

.followingFrom {
    display: flex;
    flex-direction: column;
}

.ShowGetUserTheChat-InfoUser {
    display: flex;
    align-items: center;
    height: 20%;
    /* border-bottom: 1px solid #ffff; */

}

.ShowGetUserTheChat-InfoUser>a>img {
    border-radius: 50%;
    padding: 0px 5px;
    width: 46px;
}

.ShowGetUserTheChat-InfoUser-gsd {
    display: flex;
    position: relative;
    width: 200px;
    font-size: 20px;
    justify-content: space-between;
    text-decoration: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 3px;
    gap: 0.25rem;
}

.ShowGetUserTheChat-InfoUser-gsd>a {
    padding: 0px;
    color: #fff;
}

.ShowGetUserTheChat-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 2px 10px;
}



.ShowGetUserTheChat-actions>span {
    padding: 10px;
    margin: 5px;
    background: #3f4448;
    border-radius: 5px;
}


.ShowGetUserTheChat-actions-seguir {
    cursor: pointer;
}

.ShowGetUserTheChat-actions-suscripción {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 10px;
    background: #3f4448;
    border-radius: 5px;

}

.ShowGetUserTheChat-actions-Moderator {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    padding: 0px 4px;
}

.ShowGetUserTheChat-actions-Moderator-banear {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 10px;
    background: #3f4448;
    border-radius: 5px;
    margin: 5px;
}

.ShowGetUserTheChat-actions-Moderator-TimeOut {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 10px;
    background: #3f4448;
    border-radius: 5px;
}

.ShowGetUserTheChat-actions-Moderator-vip {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 10px;
    background: #3f4448;
    border-radius: 5px;
    margin: 5px;
    text-wrap: nowrap;
}

.ShowGetUserTheChat-InfoUser-gsd-x {
    display: flex;
    cursor: pointer;
    position: relative;
    width: 76px;
    height: 58px;
    justify-content: center;
    align-items: flex-start;
}

.new-messages {
    display: flex;
    width: 100%;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin: 5px 7px;

}

.new-messages>span {
    color: #fff;
    font-weight: bolder;
    font-size: 14px;
    font-family: inter;
}

.new-messages>div {
    height: 1px;
    flex-grow: 0.4;
    background-color: #ff69c470;
}

.ResMessage {
    display: flex;
    overflow: hidden;
}

.grey-icon {
    font-size: 13px;
    color: grey;
}

.ResMessage>span {
    font-size: 12px;
    font-family: inter;
    color: #3f4243;
    padding: 0px 3px;
    font-weight: 500;
}

.donacion-chat-message-Ite {
    border: 1px solid #ff69c4;
    padding: 10px;
    margin: 9px 0px 2px 12px;
    /* margin: 10px 0; */
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 87%;
    box-shadow: inset 0px 0px 11px #ff69c4;
    background: #080808;

}

.donacion-header>span {
    padding: 3px;
    font-weight: bolder;
}

.donacion-header,
.suscripcion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.donacion-user,
.suscripcion-user {
    font-weight: bold;
    color: #4a90e2;
    padding: 5px;
}

.donacion-action,
.suscripcion-action {
    font-style: italic;
    color: #333;
    font-weight: bold;
}

.donacion-amount {
    font-weight: bold;
    color: #f36196;

}

.donacion-text>span {
    padding: 3px;
    font-weight: bold;
}

.donacion-text {
    margin-top: 5px;
    color: #555;
}

.suggestedUsers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
}

.suggestedUsers-span {
    font-size: 16px;
    font-family: inter;
    font-weight: bolder;
    color: #ffff;
    margin: 5px 0px;
    padding: 5px 5px;
    width: 94%;
    border-radius: 5px;
}

.suggestedUsers-span:hover {
    background-color: #555;
}

.suggestedUsers-span.active {
    background-color: #555;
    /* Cambia el color para resaltar la sugerencia activa */
}

.scrollToBottomChat {
    background-color: #393b40;
    padding: 3px;
    border-radius: 6px;
    width: 86%;
    cursor: pointer;
    text-align: center;
    margin-bottom: 10px;
    animation: slideUp 0.3s ease-in-out forwards;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

/* Ocultar el botón al final de la animación */
.scrollToBottomChat.hidden {
    opacity: 0;
    pointer-events: none;
    animation: none;
}

.scrollToBottomChat>span {
    font-family: inter;
    font-size: 14px;
    color: #ffffff;
}

/* Animación de subida */
@keyframes slideUp {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}