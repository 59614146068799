/* Container style */
.userStreamInfoPanel {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: #ffffff;
    width: 80%;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    animation: fadeIn 0.3s ease-in-out;
}

/* Heading style */
.userInfoSection h3,
.streamInfoSection h3 {
    color: #1a73e8;
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: 600;
}

/* User Info Section */
.userInfoSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #080808;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

/* User Info items */
.userStreamInfoPanel p {
    font-size: 1rem;
    margin: 8px 0;
    color: #b0b0b0;
}

/* Avatar styling */
.avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-top: 10px;
    border: 3px solid #1a73e8;
}

/* Buttons */
.banButton,
.partnerButton {
    background-color: #2e3032;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 1rem;
    margin-top: 15px;
}

.banButton:hover,
.partnerButton:hover {
    background-color: #1565c0;
}

.banButton:active,
.partnerButton:active {
    background-color: #0d47a1;
}

/* Partner panel modal */
.partnerPanel {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 350px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: all 0.3s ease-in-out;
}

/* Modal buttons */
.partnerPanel button {
    background-color: #4caf50;
    color: #fff;
    padding: 12px 25px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.partnerPanel button:hover {
    background-color: #45a049;
}

/* Remove partner button */
.partnerPanel button:last-child {
    background-color: #f44336;
}

.partnerPanel button:last-child:hover {
    background-color: #d32f2f;
}

/* Form input fields */
.partnerPanel input {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    color: #d1d1d1;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.partnerPanel input:focus {
    border-color: #1a73e8;
}

/* Animation for smooth transitions */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* General container background color */
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #2c1f2e;
    /* Fondo más oscuro */
    max-width: 1000px;
    margin: 0 auto;
}

/* Card background color */
.card {
    background-color: #333333;
    /* Fondo oscuro para las tarjetas */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* Sombra más prominente */
    transition: all 0.3s ease;
    margin-bottom: 20px;
    width: 89%;
}

/* Button background color */
.button {
    background-color: #4caf50;
    /* Color de fondo verde */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #388e3c;
    /* Verde más oscuro al pasar el cursor */
}

.button:active {
    background-color: #2c6c2e;
    /* Verde aún más oscuro cuando se hace clic */
}

/* Background color for input fields */
.main-container input {
    width: 100%;
    padding: 10px;
    /* margin-top: 15px;     */
    border-radius: 5px;
    border: 1px solid #444;
    /* Bordes oscuros */
    background-color: #222222;
    /* Fondo más oscuro */
    color: #fff;
    /* Texto blanco */
    transition: border 0.3s ease;
}

.main-container input:focus {
    outline: none;
    border: 1px solid #4caf50;
    /* Color verde cuando está enfocado */
    background-color: #333333;
    /* Fondo más claro al hacer foco */
}

/* Panel de información de usuario */
.userStreamInfoPanel {
    background-color: #080808;
    /* Fondo más oscuro */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

.userStreamInfoPanel h3 {
    margin-bottom: 15px;
    color: #fff;
    /* Texto blanco */
}

/* Buttons in the modal */
.partnerPanel button {
    background-color: #4caf50;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.partnerPanel button:hover {
    background-color: #388e3c;
}

.partnerPanel button:last-child {
    background-color: #f44336;
    /* Rojo para el botón de eliminar */
}

.partnerPanel button:last-child:hover {
    background-color: #d32f2f;
    /* Rojo más oscuro */
}

/* Avatar styling */
.avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-top: 10px;
    margin-right: 15px;
    border: 3px solid #1a73e8;
    /* Mantén el borde azul claro */
}

/* Modal styles */
.partnerPanel {
    background-color: #333333;
    /* Fondo más oscuro */
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
}