.player-body {
    width: 500px;
}


.player-video {
  width: 100%;
}

.player-control-container {
  width: 100%;
  opacity: 1;
  transition: 0.5s all;
}

.player-shadow-1 {
  opacity: 0;
  width: 98%;
  margin-top: -50px;
  box-shadow: 10px 10px 40px 25px rgb(0, 0, 0);
  position: relative;
  transition: 0.5s all;

}


.player-control-primary {
  width: 95%;
  margin: 0 auto;
  min-height: 50px;
  display: flex;
  align-items: center;
  margin-top: -85px;
  color: #ededed;
}
  

.time_progressbarContainer {
  background-color: gray;
  box-shadow: 2px 2px 20px 2px black;
  border-radius: 15px;
  width: 95%;
  height: 5px;
  z-index: 30;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
}

.time_progressBar {
  border-radius: 15px;
  height: 100%;
  z-index: 30;
  background-color: #ff60b2;
}

.controlsTime {
  color: #ededed;
  z-index: 30;
  margin-left: 5px;
  font-family: 'Montserrat';
  font-size: 12px;
}

.player-control-secundary {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.player-control-secundary-div {
  width: 50%;
  color: #ededed;
  display: flex;
  justify-content: left;
  align-items: center;
}


.player-control-secundary-div2 {
  width: 50%;
  color: #ededed;
  display: flex;
  justify-content: right;
  align-items: center;
}

.player-control-card {
  z-index: 30;
}

.player-body:hover .player-control-container {
  opacity: 1;
}

.player-body:hover .player-shadow-1 {
  opacity: 1;
}