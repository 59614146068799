.search-body {
    display: flex;
    background-color: #080808;
    width: 100%;
    min-height: 1000px !important;
    padding: 1rem 4rem;
    flex-wrap: wrap;
}

.search-container {
    margin: 17px;
    position: relative;
    top: 69px;
    left: 20px;
    width: 71%;
}

.search-info-canales {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}


.search-info-canales-card {
    width: 150px;
    height: 220px;
    padding: 10px;
    text-align: center;
    background-color: #3030307c;
    border-radius: 5px;
    transition: 0.6s;

}

.search-info-canales-card-image {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 200px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);
    position: relative;
}


.search-info-canales-card:hover {
    background-color: #303030;
}

.search-info-canales>a {
    margin: 10px;
}

@media screen and (max-width: 758px) {
    .search-container {
        margin: 17px !important;
        width: 100% !important;
    }
}