@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdownpoints-menu {
  position: absolute;
  margin-top: -348px;
  left: 0px;
  width: 342px;
  height: 364px;
  display: flex;
  background: #0a0a0afc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
  border: 1px solid #15151563;
  overflow: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid #ffffff1a;

}

.dropdownpoints-menu:hover {
  overflow-y: scroll;
}

.dropdownpoints-menu li {
  cursor: pointer;
  font-size: 13px;
  border-radius: 5px;
}

.dropdownpoints-menu i {
  transition: 0.3s all;

}

.dropdownpoints-menu li:hover .dropdownpoints-content i {
  transform: translateY(-3px);
}

.dropdownpoints-menu li:hover {
  background: #2e2e2e;
}

.dropdownpoints-menu.clicked {
  display: none;
}

.dropdownpoints-link {
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 5px;
}

.dropdownpoints-button {
  background: none;
  border: none;
  width: 80%;
  font-family: 'Montserrat';
  font-weight: 800;
  height: 100%;
  padding: 16px;
  color: #ededed;
  cursor: pointer;

}

.dropdownpoints-content {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  color: #ededed;
  font-family: 'Montserrat';
  font-weight: 800;
}

.dropdownpoints-button-okay {
  width: 80%;
  margin: 0 auto;
  padding: 5px;
  font-size: 14px;
  font-family: "Poppins";
  color: #ededed;
  background-color: #ff60b2;
  border-radius: 5px;
  cursor: pointer;
}

.dropdownpoints-card-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;
}

.dropdownpoints-card {
  height: 75px;
  min-width: 32%;
  margin-top: 5px;
  max-width: 32%;
  background-color: #151515;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownpoints-card:hover {
  background-color: #303030;
}


.dropdownpoints-card-disabled {
  height: 75px;
  min-width: 90px;
  margin-right: 10px;
  margin-top: 5px;
  max-width: 90px;
  background-color: #070707;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: antiquewhite;
}

.dropdownpoints-card-disabled:hover {
  background-image: url("../../../../../images/disabled.png");
  background-position: center;
  background-size: cover;
}

.dropdownpoints-input {
  width: 95%;
  background-color: #303030;
  border: 1px solid rgba(39, 39, 39, 0.418);
  margin-top: 10px;
  padding: 7px;
  font-family: "Poppins";
  font-size: 12px;
  outline: none;
  color: lightgray;
  border-radius: 3px;
}


.dropdownpoints-button-send {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  padding: 7px;
  font-size: 14px;
  font-family: "Poppins";
  color: #ededed;
  background-color: #ff60b2;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}


.dropdownpoints-text {
  font-family: "Poppins";
  font-size: 12px;
  color: #ededed;
  margin-top: 10px;
}



@media screen and (max-width: 960px) {

  .dropdownpoints-menu {
    width: 220%;
    /* top: 60px; */
    z-index: 200;

  }

  .fa-caret-down {
    display: none;
  }
}