/* BuyAds.css */

.buy-ads-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 40px;
    background: #080808;
    min-height: 100vh;
    color: #e0e0e0;
    font-family: inter;
}

.info-section {
    text-align: center;
    margin-bottom: 40px;
    width: 100%;
}


.section-title {
    font-size: 2.5rem !important;
    font-weight: bold !important;
    color: #ffffff !important;
    margin-bottom: 15px !important;
}

.section-description {
    font-size: 1.2rem !important;
    color: #f36196f5;
    font-weight: bolder !important;
}

.ads-options {
    width: 100%;
}

.options-title {
    font-size: 2rem !important;
    font-weight: bold !important;
    color: #ffffff !important;
    margin-bottom: 20px !important;
}

.ad-card {
    border-radius: 12px !important;
    box-shadow: 0px 2px 1px -1px rgb(159 71 140), 0px 1px 1px 0px rgb(190 90 191), 0px 1px 3px 0px rgb(152 69 140) !important;
    overflow: hidden !important;
    /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); */
    padding: 20px !important;
    height: 400px !important;
}

.content-data-ads {
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: nowrap;
    justify-content: space-around;
    height: 360px;
}

.ad-card.muro {
    background: #080808;
}

.ad-card.clips {
    background: #080808;
}

.ad-card.streams {
    background: #080808;
}

.card-title {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    color: #ffffff !important;
}

.card-description {
    font-size: 1rem !important;
    color: #e2d8e0;
    font-weight: bolder !important;
}

.price {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    margin-top: 10px !important;
}

.price.muro {
    color: #f36196f5;
}

.price.streams {
    color: #f36196f5;
}

.ad-details {
    font-size: 0.9rem !important;
    color: #e2d8e0 !important;
    margin-top: 10px !important;
    font-weight: 600 !important;
    letter-spacing: 0.07071em !important;
}

.buy-button {}

.MuiButton-root.buy-button {
    margin-top: 15px;
    background-color: #100b0d;
    color: #ffffff;
    font-weight: bold;

    &:hover {
        background-color: #f3619645;
    }
}

.faq-section {
    margin-top: 40px;
    width: 100%;
}

.faq-title {
    font-size: 2rem !important;
    font-weight: bold !important;
    color: #ffffff;
    margin-bottom: 20px !important;
}

.faq-description {
    font-size: 1rem;
    color: #b0b0b0;
}