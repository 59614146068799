.overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5);
}

.mute-icon {
    color: white;
    font-size: 48px;
    font-weight: bold;
}

.validationFaildPlayer {
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #762543;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border: 1px solid #ff60b2;
    list-style-type: none;
    /* Quita los puntos */

}

.validationFaildPlayer ul {
    list-style-type: none;
    margin: 5px 0px;
}

:root {
    --navbar-height: 50px;
    --channel-info-height: 30px;
}


.video-player {
    max-height: calc(84dvh - var(--navbar-height) - var(--channel-info-height));
    /* height: 649px !important; */
    object-fit: cover;
}

.fullscreen {
    max-height: calc(111dvh - var(--navbar-height) - var(--channel-info-height))
}

.thumbnail-prev-PlayerMain {
    height: calc(84dvh - var(--navbar-height) - var(--channel-info-height));
    width: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 758px) {
    .thumbnail-prev-PlayerMain {
        height: calc(40dvh - var(--navbar-height) - var(--channel-info-height));
        width: 100%;
    }
}

.base-dialog {
    display: flex;
    width: 70%;
    max-height: 200px;
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
    flex-direction: column;
    align-items: center;
    background: #202225;
    color: #fff;
    justify-content: space-between;
    border-radius: 10px;
}

.base-dialogo-Adver {
    text-align: center;
    font-weight: bold;
}

.base-dialogo-transmisión {
    text-align: center;
    font-weight: bold;
}

.base-button {
    margin: 0px;
}

.dialog-actions {
    width: 100%;
    margin: 0px;
    display: flex;
    justify-content: space-between;
}

.dialog-actions>button {
    margin: 0px;
    background-color: #b32a65;
}

#ambilight {
    filter: blur(80px) opacity(0.5) saturate(300%);
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: scale(1.1) translateZ(0);
    width: 100%;
    z-index: -1;
    height: 100%;
}

.base-dialog-player {
    display: flex;
    width: 10%;
    max-height: 0;
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
    flex-direction: column;
    align-items: center;
    background: #202225;
    color: #fff;
    justify-content: space-between;
    border-radius: 10px;
}

.VideoPlayerCreateClip {
    width: 600px !important;
    height: auto !important;
}