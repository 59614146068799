
.popupfollowers-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 9999;
}

.popupfollowers-container {
    width: 23%;
	position: absolute;
	left: 25%;
	right: 25%;
	top: 25%;
	bottom: 25%;
	margin: auto;
	text-align: center;
    background: #0a0a0afc;
    border: 1px solid #ffffff1a;
    border-radius: 5px;
    z-index: 1001;

}

.popupfollowers-close {
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: right;
    position: relative;
    left: 20px;
    top: 0px;
}

.popupfollowers-close button{
    background-color: transparent;
    border: none;
    color: #ededed;
    cursor: pointer;
}


.popupfollowers-content {
    width: 100%;
    margin-top: 10px;
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}
