.home-body-dark:hover .pinkker-scrollbar {
    opacity: 1;
}


.home-body-dark {
    width: 100%;

    background-color: transparent;
    overflow: hidden;
    overflow-y: scroll;
    background: #080808;
}

.pinkker-scrollbar {
    opacity: 0;
    border-bottom: 1px #2b2b2b8f;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    position: fixed;
    top: 0;
    bottom: 0;
    min-height: 1000px;
    right: 0;
    width: 7px;
    z-index: 10;
    transition: 0.4s;
}

.home-img-content {
    position: absolute;
    z-index: 2000;
}

.pinkker-scrollbar-tab {
    position: relative;
    height: 60px;
    width: 7px;
    background-color: #f36197cc;
    border-radius: 30px;
}


.home-body-light {
    width: 100%;
    position: relative;
    background-color: #f9f9f9;
    min-height: 2000px;
}

.home-body-blue {
    width: 100%;
    position: relative;
    background-color: #192734;
    min-height: 2000px;
}

.categories-home-container {
    overflow: hidden;
    width: 100%;
}

.categories-home-manager {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.categories-home-container h2 {
    font-weight: bold;
    font-size: 20px;
    font-family: inter;
    color: #fff;
}

.categories-home {
    display: flex;
    margin: 10px -10px;
}

@media screen and (max-width: 758px) {
    .home-img {
        width: 100% !important;
        min-height: 50px !important;
        z-index: 9999;
    }
}

@media screen and (min-width: 1400px) {
    .home-img {
        width: 100%;
        min-height: 450px;
        background-color: black;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
        color: #ededed;
        display: flex;
        justify-content: center;
        align-items: center;
        text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.6);
        transition: 0.5s all;
        overflow: hidden;
    }

    .home-body-dark {
        width: 100%;
        background-color: transparent;
        overflow: hidden;
        overflow-y: scroll;
        background: #080808;

    }
}


.test {
    width: 100%;
    height: 450px;
    overflow: hidden;
}


.home-button {
    margin: 10px auto;
    padding: 10px;
    background-color: #b83c78;
    color: #ededed;
    border: none;
    font-family: 'Poppins';
    width: 200px;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0 0 16px #ff073a;
    text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.6);
}


/*.home-img:hover {
    box-shadow: -12px 0px 200px 155px rgb(0, 0, 0) inset;

}*/


.home-img:hover .home-button:hover {
    background-color: #ff60b2;
}

.home-button:hover i {
    margin-left: 15px;
}


.home-image-effect {
    position: absolute;
    transform: skew(-18deg, 2deg) rotate(4deg);
    transition: 0.2s;
    filter: brightness(30%);
}

.home-image-effect:hover {
    color: #ff693d;
    font-weight: bold;
    box-shadow: #ff85d9 1px 1px, #ff85d9 2px 2px, #ff85d9 3px 3px, #ff85d9 4px 4px, #ff85d9 5px 5px, #ff85d9 6px 6px, #ff85d9 7px 7px, #ff85d9 8px 8px;
    cursor: pointer;
    transform: skew(-18deg, 2deg) rotate(4deg) scale(1.11);
    z-index: 1400;
    filter: brightness(100%);
}


.home-image-banner-effect {
    position: absolute;
    transform: skew(-18deg, 2deg) rotate(4deg);
    transition: 0.2s;
    filter: brightness(30%);

}



.home-image-banner-effect:hover {
    color: #ff693d;
    font-weight: bold;
    box-shadow: #ff85d9 1px 1px, #ff85d9 2px 2px, #ff85d9 3px 3px, #ff85d9 4px 4px, #ff85d9 5px 5px, #ff85d9 6px 6px, #ff85d9 7px 7px, #ff85d9 8px 8px, #ff85d9 9px 9px;
    cursor: pointer;
    transform: skew(-18deg, 2deg) rotate(4deg) scale(1.09);
    z-index: 1400;
    filter: brightness(100%);
}

.home-image-container {
    background-color: red;
    width: 100%;
    height: 450px;
}

.home-image-primary {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0px;
    transform: rotate(4deg);
}

.home-image-secondary {
    width: 100%;
    height: 150px;
    position: absolute;
    top: 200px;
    transform: skew(-18deg, 2deg) rotate(4deg) scale(1.11);
}

@keyframes translateAnimation {
    0% {
        transform: skew(-18deg, 2deg) rotate(4deg);
    }

    50% {
        transform: skew(-18deg, 2deg) rotate(4deg) scale(1.03);
        box-shadow: #ff85d9 1px 1px, #ff85d9 2px 2px, #ff85d9 3px 3px, #ff85d9 4px 4px, #ff85d9 5px 5px, #ff85d9 6px 6px, #ff85d9 7px 7px, #ff85d9 8px 8px, #ff85d9 9px 9px;
    }

    100% {
        transform: skew(-18deg, 2deg) rotate(4deg);
    }
}

@keyframes translateAnimationHover {
    0% {
        transform: skew(-18deg, 2deg) rotate(4deg) scale(1.11);
    }

    50% {
        transform: skew(-18deg, 2deg) rotate(4deg) scale(1.11);
    }

    100% {
        transform: skew(-18deg, 2deg) rotate(4deg) scale(1.11);
    }
}





@media screen and (max-width: 768px) {

    .home-body-dark {
        width: 100%;

    }

    .home-img-content {
        width: 80%;
        margin: 0 auto;
    }


}

