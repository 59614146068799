.resetpassword-body {
    width: 100%;
    min-height: 1000px;
}

.resetpassword-container {
    width: 95%;
    margin: 0 auto;
}

.resetpassword-card-container {

    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #ededed;
    width: 100%;

    border-radius: 5px;
}

.resetpassword-input {
    background-color: #151515;
    margin-top: 10px;
    padding: 10px;
}

.resetpassword-input h1 {
    font-size: 14px;
}


.resetpassword-button {
    margin-top: 50px;
    position: relative;
    width: 140px;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #f36196;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
}


.resetpassword-button:hover {
    background-color: #762543;
}

.biography-content-soli {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #191b1f;
    border-radius: 5px;
    padding: 15px;
}

.resetpassword-button-soli {
    position: relative;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #f36196;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}


.resetpassword-button-soli:hover {
    background-color: #762543;
}



@media screen and (max-width: 768px) {
    .resetpassword-body {
        min-height: 1700px;
    }

    .resetpassword-card-container {
        width: 90%;
    }

}