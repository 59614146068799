.dropdownretweet-body {
    z-index: 100000000000000;
}

.dropdownretweet-container {
    /* position: absolute; */
    z-index: 100000000000000;
    background-color: #151515;
    height: 70px;
    padding: 20px;
    width: 120px;
    left: 15px;
    border-radius: 20px;
    margin-top: 19px;
    box-shadow: 1px 5px 23px 0px rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 1px 5px 23px 0px rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 1px 5px 23px 0px rgba(255, 255, 255, 0.3);
}

.dropdownretweet-card {
    padding: 5px;
    display: flex;
    align-items: center;
}

.dropdownretweet-card>i {
    padding-right: 10px;
}