.withdraw-popup-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 9999;
   
}

.withdraw-popup-container {
    width: 20%;
    height: 350px;
	margin: auto;
    padding: 30px;
	background: #18181b;
	border-radius: 5px;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
}

.widthdraw-popup-methods {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.widthdraw-popup-method {
	width: 175px;
	background-color: #151515;
	border-radius: 5px;
	cursor: pointer;
	margin-right: 10px;
}

.widthdraw-popup-method:hover {
	background-color: #303030;
}


.withdraw-popup-send {
	width: 100px;
	padding: 7px;
	background-color: #ff60b2;
	border: none;
	border-radius: 3px;
	color: #ededed;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	margin-top: 10px;
}

.withdraw-popup-send:hover {
	background-color: #bd3b7e;
}

.withdraw-popup-input {
	width: 250px;
	height: 35px;
	outline: none;
	background-color: #151515;
	border: none;
	border-radius: 3px;
	color: #ededed;
	font-size: 14px;
	font-weight: 600;
	padding-left: 10px;
	margin-top: 5px;
}

.withdraw-popup-input:focus {
	background-color: #000000;
	outline: 1px solid #ff60b2;
}