@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.dropdowngiftsuscription-menu {
  position: absolute;
  left: 54%;
  top: 180px;
  width: 420px;
  height: 650px;
  display: flex;
  background: #18181bf8;
  box-shadow: 0px 5px 20px 5px black;
  border-radius: 5px;
  z-index: 100000000;
}

.dropdowngiftsuscription-menu li {
  cursor: pointer;
  display: flex;
  text-align: center;
  font-size: 12px;
  border-radius: 5px;
}

.dropdowngiftsuscription-menu i {
  transition: 0.3s all;

}

.dropdowngiftsuscription-menu li:hover .dropdowngiftsuscription-content i {
  transform: translateY(-3px);
}

.dropdowngiftsuscription-menu li:hover {
  background: #0e0e0e;
}

.dropdowngiftsuscription-menu.clicked {
  display: none;
}

.dropdowngiftsuscription-link {
  display: flex;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 12px;
}

.dropdowngiftsuscription-button {
  background: none;
  border: none;
  width: 80%;
  font-family: 'Montserrat';
  font-weight: 800;
  height: 100%;
  padding: 16px;
  color: #ededed;
  cursor: pointer;

}

.dropdowngiftsuscription-content {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  color: #ededed;
  font-family: 'Montserrat';
  font-weight: 800;
}




.dropdowngiftsuscription-radio input[type="radio"] {
  background-color: rgb(105, 105, 105);
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  height: 15px;
  margin-right: 5px;
  position: relative;
  width: 15px;
  -webkit-appearance: none;
}

.dropdowngiftsuscription-radio input[type="radio"]:after {
  border-radius: 25px;
  background-color: rgb(71, 71, 71);
  content: '';
  display: block;
  height: 9px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 9px;
}

.dropdowngiftsuscription-radio input[type="radio"]:checked:after {
  background-color: #ff60b2;
}


.dropdowngiftsuscription-streamer {
  display: flex;
}

.dropdowngiftsuscription-button-sub {
  margin-top: 20px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background-color: #9147ff;
  border: none;
  color: #ededed;
  border-top-left-radius: 5px;
  border-end-start-radius: 5px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.suscriptiondropdown-price-buttons-container:hover .dropdowngiftsuscription-button-sub {
  background-color: #803ee3;
}

.dropdowngiftsuscription-button-sub-price {
  width: 110px;
  padding: 6px;
  background-color: #534bb3;
  border: none;
  color: #ededed;
  border-radius: 5px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.suscriptiondropdown-price-buttons-container:hover .dropdowngiftsuscription-button-sub-price {
  background-color: #3c3583;
}

.dropdowngiftsuscription-button-gift {
  margin-top: 20px;
  width: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background-color: #202020;
  border: none;
  color: #ededed;
  border-radius: 5px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.dropdowngiftsuscription-button-gift:hover {
  background-color: #303030;
}

.dropdowngiftsuscription-card-container {
  width: 100%;
  height: 450px;
}

.dropdowngiftsuscription-card {
  width: 100%;
  height: 75px;
  background-color: #252525;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #ededed;
  margin-top: 5px;
}

.dropdowngiftsuscription-card-primary {
  width: 30%;
}

.dropdowngiftsuscription-card-secondary {
  width: 35%;
  text-align: left;
}

.dropdowngiftsuscription-card-tercery {
  width: 35%;
  text-align: center;
}


.dropdowngiftsuscription-button-user-gift {
  background-color: #5f25b5;
  padding: 5px;
  border: none;
  color: white;
  font-weight: 800;
  border-radius: 3px;
  cursor: pointer;
}

.giftsuscription-card-type-1 {
  background-color: #303030;
  width: 100px;
  margin-top: 10px;
  height: 85px;
  color: #ededed;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}



@media screen and (max-width: 960px) {

  .dropdowngiftsuscription-menu {
    width: 220%;
    top: 60px;
    z-index: 200;

  }

  .fa-caret-down {
    display: none;
  }
}